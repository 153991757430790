export const getCatalog = (arrayCatalogs, catalogName) => {
  if (typeof arrayCatalogs === 'undefined' || arrayCatalogs === null) return null;
  return arrayCatalogs.find((catalog) => (catalog.code === catalogName ? catalog : null));
};

export const getCatalogValue = (arrayCatalogs, catalogName, catalogValueCode) => {
  const catalog = getCatalog(arrayCatalogs, catalogName);
  if (typeof catalog === 'undefined' || catalog === null) return catalogValueCode;

  const catalogValue = catalog.catalogValues.find((catalogValue) => {
    return catalogValue.code === catalogValueCode;
  });

  if (typeof catalogValue === 'undefined' || catalogValue === null) return catalogValueCode;
  return catalogValue.name;
};
