import { InvestmentProvider } from './context/InvestmentContext';
import { InvestmentTypeLayout } from './InvestmentTypeLayout';

export function Investment() {
  return (
    <InvestmentProvider>
      <InvestmentTypeLayout />
    </InvestmentProvider>
  );
}
