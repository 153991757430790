import { useMsal } from '@azure/msal-react';
import { isObjectEmpty } from '../utils/CheckUtils';

export const useUserHaveRole = (searchingRole) => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();

  if (isObjectEmpty(currentAccount)) return false;

  return currentAccount.idTokenClaims['roles']?.some((role) => role === searchingRole);
};
