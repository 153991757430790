import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

FormDetailField.propTypes = {
  controlId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  sizeLabel: PropTypes.number,
  sizeCol: PropTypes.number,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

/**
 * Creates a form detail field component.
 *
 * @param {string} controlId - The control ID for the form field.
 * @param {string} className - The class name for styling the form field.
 * @param {number} sizeLabel - The size for the label column.
 * @param {number} sizeCol - The size for the value column.
 * @param {string} label - The label for the form field.
 * @param {string} value - The value to be displayed in the form field.
 * @return {JSX.Element} The form detail field component.
 */
export function FormDetailField({ controlId, className, sizeLabel, sizeCol, label, value }) {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={className} column xl={sizeLabel}>
        {label}:
      </Form.Label>
      <Form.Label column xl={sizeCol}>
        {value}
      </Form.Label>
    </Form.Group>
  );
}
