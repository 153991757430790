import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export function useInvestmentColumns() {
  const InvestmentColumns = [
    {
      accessorKey: 'code', //access nested data with dot notation
      header: 'Code',
      Cell: ({ cell }) => (
        <Nav.Link className="linkTable" as={NavLink} to={'/investmenttype/' + cell.getValue()}>
          {cell.getValue()}
        </Nav.Link>
      ),
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'subtypeDescription',
      header: 'SubType',
    },
    {
      accessorKey: 'minAmount',
      header: 'Min Amount',
    },
    {
      accessorKey: 'maxAmount',
      header: 'Max Amount',
    },
    {
      accessorKey: 'requiresAttached',
      header: 'Requires attachment',
      Cell: ({ cell }) => (
        <>
          {cell.getValue() ? (
            <ion-icon name="checkmark-outline"></ion-icon>
          ) : (
            <ion-icon name="close-outline"></ion-icon>
          )}
        </>
      ),
    },
  ];

  return InvestmentColumns;
}
