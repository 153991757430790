import { Button, Col, Form, Row } from 'react-bootstrap';
import { Skeleton } from '@mui/material';

export function SkeletonApproversViewers() {
  const sizeLabel = 2;

  return (
    <>
      <Form>
        <Form.Group as={Row} className="mb-1" controlId="group">
          <Form.Label column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Form.Label column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Col>
            <Button className="botonComment">
              <Skeleton width={180} />
            </Button>
          </Col>
        </Form.Group>
      </Form>
      <Row>
        <Col>
          <strong>
            <Skeleton width={180} />
          </strong>
        </Col>
      </Row>
      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <Skeleton width={180} height={300} />
      </div>
    </>
  );
}
