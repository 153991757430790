import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { isArrayEmpty } from '../utils/CheckUtils';
import { useIsUserAuthorized } from './useIsUserAuhtorized';

export const useCanUserViewComponent = (props) => {
  const { instance } = useMsal();
  const [canUserViewComponent, setCanUserViewComponent] = useState(false);
  const [isCheking, setIsCheking] = useState(true);

  const currentAccount = instance.getActiveAccount();
  const isUserValid = useIsUserAuthorized();

  //realiza la lógica de roles al renderizar o si cambia la cuenta activa o se inicia una nueva sesión.
  useEffect(() => {
    // Obtiene los roles del claim de la cuenta Activa "currentAccount" y los cruza con el "arrayRoles" que se pasa por
    // parámetro. Si alguno coincide, hace un set de la autorización que se pasa en "authorize"
    function setAuthorizeIntersectClaimsRoles(currentAccount, arrayRoles, authorize) {
      //Comprueba si tiene el claim "roles" de aplicación el usuario
      if (currentAccount?.idTokenClaims['roles']) {
        let intersection = arrayRoles.filter((role) =>
          currentAccount.idTokenClaims['roles'].includes(role)
        );

        if (intersection.length > 0) {
          setCanUserViewComponent(authorize);
        } else {
          // Si se excluye un rol, debe de estar autorizado, si se solicita un rol debe de estar desautorizado.
          setCanUserViewComponent(!authorize);
        }
      } else {
        //el usuario no tiene roles. Por lo que debe de hacerse lo contrario que se autoriza.
        // Si se excluye un rol, debe de estar autorizado, si se solicita un rol debe de estar desautorizado.
        setCanUserViewComponent(!authorize);
      }
      setIsCheking(false);
    }

    //Comprobación de roles
    function onLoad() {
      const { roles, exclude } = props;
      //Precalcula las clausulas si existen las props
      const isRolesPropsEmpty = isArrayEmpty(roles);
      const isExcludePropsEmpty = isArrayEmpty(exclude);
      setIsCheking(true);
      let listRoles = roles;

      if (!isUserValid) {
        setCanUserViewComponent(false);
        setIsCheking(false);
        return;
      }

      //valida si se ha pasado roles=[] o exclude=[] como props, en caso que no se informe ninguna autoriza
      if (isRolesPropsEmpty && isExcludePropsEmpty) {
        setCanUserViewComponent(true);
        setIsCheking(false);
        return;
      }

      //Si no hay roles=[],  No autoriza a aquellos roles que se le pase por el exclude=[]
      if (isRolesPropsEmpty && !isExcludePropsEmpty) {
        setAuthorizeIntersectClaimsRoles(currentAccount, exclude, false);
        return;
      }

      //Filtra aquellos roles=[] que estén en exclude=[]. Prevalecen la lista de excluidos.
      // Si un rol se encuentra en ambas listas, se eliminará y por tanto se quitará acceso.
      if (!isRolesPropsEmpty && !isExcludePropsEmpty)
        listRoles = roles.filter((x) => !exclude.includes(x));

      //Valida que el usuario tenga los roles que se pasan por props al componente
      setAuthorizeIntersectClaimsRoles(currentAccount, listRoles, true);
    }

    onLoad();
  }, [instance, currentAccount, props, canUserViewComponent, isUserValid]);

  return { canUserViewComponent, isCheking };
};
