import { DocumentList } from './DocumentList';
import { allowedExtensions } from '../../../config/DocumentsConfig';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_ATTACHMENTS_PROJECT } from '../../../config/ApiURLs';
import { useProjectContext } from '../context/ProjectContext';
import { useEffect, useState } from 'react';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';
import { isObjectEmpty } from '../../../utils/CheckUtils';

const Documents = () => {
  const [loadSpinnerDocument, setLoadSpinnerDocument] = useState(false);
  const {
    modalResponseDocument,
    setModalResponseDocument,
    isErrorDocument,
    setIsErrorDocument,
    msgDocument,
    setMsgDocument,
    isWarningDocument,
    setIsWarningDocument,
  } = useProjectContext();

  useEffect(() => {}, [loadSpinnerDocument, modalResponseDocument]);

  const {
    projectUid,
    setReloadDocuments,
    isNewProject,
    documentsAttachedNewProject,
    setDocumentsAttachedNewProject,
  } = useProjectContext();

  const handleOnChange = async (event) => {
    setLoadSpinnerDocument(true);
    const files = [...documentsAttachedNewProject];
    //TO-DO:Control tamaño de archivo
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    chosenFiles.forEach((file) => {
      if (files.findIndex((f) => f.name === file.name) === -1) {
        files.push(file);
      }
    });
    setDocumentsAttachedNewProject(files);
    setLoadSpinnerDocument(false);
  };

  const uploadFile = async (event) => {
    setLoadSpinnerDocument(true);
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    const formData = new FormData();
    //TO-DO:Control tamaño de archivo
    chosenFiles.forEach((file) => {
      formData.append('documents', file);
    });
    try {
      await axiosApiInstance.post(API_ATTACHMENTS_PROJECT + `/${projectUid}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMsgDocument('Document added to project correctly!!');
      setIsErrorDocument(false);
    } catch (error) {
      const isErrorFromResponse = !isObjectEmpty(error.response);
      const errorMessage = isErrorFromResponse ? error.response.data.Message : error.message;
      setIsErrorDocument(true);
      setMsgDocument(
        isErrorFromResponse ? errorMessage : 'There has been a problem loading the document'
      );
      console.log('Error uploadFile -> ' + error);
    } finally {
      setIsWarningDocument(false);
      setLoadSpinnerDocument(false);
      setModalResponseDocument(true);
    }

    setReloadDocuments(true);
    setLoadSpinnerDocument(false);
  };

  const handleModalCallbackButton = () => {
    setReloadDocuments(true);
  };

  const handleModalCallbackClose = () => {
    setReloadDocuments(true);
  };

  return (
    <>
      {modalResponseDocument && (
        <ModalResponse
          showModal={modalResponseDocument}
          isError={isErrorDocument}
          isWarning={isWarningDocument}
          message={msgDocument}
          buttonAccept={!isErrorDocument && !isWarningDocument}
          callbackButton={handleModalCallbackButton}
          callbackClose={isErrorDocument ? undefined : handleModalCallbackClose}
        />
      )}
      {loadSpinnerDocument ? (
        <SpinnerButtons text="Adding documentation..." textButton=""></SpinnerButtons>
      ) : (
        <>
          <div>
            <div className="image-upload-wrap">
              <input
                className="file-upload-input"
                type="file"
                accept={allowedExtensions}
                multiple
                onChange={(event) => (isNewProject ? handleOnChange(event) : uploadFile(event))}
              />
              <div>
                <h3 className="text-information">
                  <ion-icon name="cloud-upload-outline"></ion-icon>&nbsp; Drop files to attach or
                  browse
                </h3>
              </div>
            </div>
          </div>
          <div>
            <DocumentList />
          </div>
        </>
      )}
    </>
  );
};

export default Documents;
