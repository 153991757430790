import logo from '../assets/dacsa-logo.svg';

export function LogoApp(props) {
  return (
    <div
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <div className="text-center">
        <img src={logo} alt="Dacsa Group" />
      </div>
      <br />
      <div className="text-center">
        <p>
          <strong data-sourcepos="1:1-1:30" dir="auto">
            Dacsa Group - Capex Approval App
          </strong>
        </p>
        <small>CAPEX approval tool by SopraSteria</small>
      </div>
      <br />
      <div className="text-center">{props.children}</div>
    </div>
  );
}
