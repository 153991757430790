import { Form, Row, Col, Badge } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import {
  utcStringDateTimeToLocalDateFormat,
  utcStringDateTimeToLocalDateTimeFormat,
} from '../../../utils/DateTimeUtils';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';
import { FormCatalogDetailField } from '../../../components/FormCatalogDetailField';

const ProjectDetail = ({ project }) => {
  const sizeLabel = 4;
  const sizeCol = 8;

  document.getElementById('root').scrollIntoView(true);
  const [haveSubheadquarters, sethaveSubheadquarters] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);

  const { TaskSuperUser } = appRoles;
  const selfApproval = project.selfApproval ? 'checked' : '';
  const showTypeofproject = project.type === 'SPECIALS';

  useEffect(() => {
    if (typeof project.subheadquartersId !== 'undefined' && project.subheadquartersId !== null) {
      sethaveSubheadquarters(true);
    }

    if (project.type === 'CORPORATE') {
      setShowDepartment(true);
    }
  }, [project, sethaveSubheadquarters, setShowDepartment]);

  return (
    <>
      <Row>
        <Col xl={{ order: 'first' }} className="col-md-8">
          <Row>
            <Col>
              <strong>Details</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="requestName">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Name:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.name}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="status">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  CAPEX Code:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.capexCode}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="type">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Type:
                </Form.Label>
                <Badge className="dacsa-yellow">{project.type}</Badge>
              </Form.Group>
              <Form.Group controlId="headquarters">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Headquarters:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.headquartersDescription}
                </Form.Label>
              </Form.Group>
              {showDepartment && (
                <Form.Group controlId="department">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    Department:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.departmentDescription}
                  </Form.Label>
                </Form.Group>
              )}
              {showTypeofproject && (
                <FormCatalogDetailField
                  className="colorGrey"
                  catalogType="typeofproject"
                  value={project.typeofprojectDescription}
                  sizeLabel={sizeLabel}
                  sizeCol={sizeCol}
                />
              )}
              <Form.Group controlId="area">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Area:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.areaDescription}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="budget">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Budget:
                </Form.Label>
                <Badge className="dacsa-green fontSize-18">{project.budget}</Badge>&nbsp;
                <Form.Label column xl={2}>
                  {project.badgeId}
                </Form.Label>
              </Form.Group>
              <AuthenticatedComponent roles={[TaskSuperUser]}>
                <Form.Group as={Row} className="mb-3" controlId="selfApproval">
                  <Form.Label column xl={sizeLabel}>
                    Self approved:
                  </Form.Label>
                  <Col xl="2" className="checkIndent-1">
                    <Form.Check type="checkbox" checked={selfApproval} readOnly />
                  </Col>
                </Form.Group>
              </AuthenticatedComponent>
            </Col>
            <Col>
              <Form.Group controlId="status">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Status:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.status}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="void1">
                <Form.Label column xl={sizeLabel}>
                  &nbsp;
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="subtype">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Subtype:
                </Form.Label>
                <Badge className="dacsa-yellow">{project.subtypeDescription}</Badge>
              </Form.Group>
              {haveSubheadquarters ? (
                <Form.Group controlId="subheadquarters">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    Sub-headquarters:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.subheadquartersId}
                  </Form.Label>
                </Form.Group>
              ) : (
                <Form.Group controlId="void2">
                  <Form.Label column xl={sizeLabel}>
                    &nbsp;
                  </Form.Label>
                </Form.Group>
              )}
              <Form.Group controlId="category">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Category:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.categoryDescription}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Start date:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {utcStringDateTimeToLocalDateFormat(project.startDate)}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="expectedEndDate">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Expected end date:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {utcStringDateTimeToLocalDateFormat(project.expectedEndDate)}
                </Form.Label>
              </Form.Group>
            </Col>
            <Form.Label className="colorGrey">Summary:</Form.Label>
            <Form.Control
              className="colorGrey indent-1"
              as="textarea"
              placeholder="Project summary"
              cols="40"
              rows="5"
              value={project.summary}
              disabled
            />
          </Row>
        </Col>
        <div className="col-1 separador"></div>
        <Col xl={{ order: 'last' }}>
          <Row>
            <strong>People</strong>
            <Col>
              <Form.Group controlId="projectManager">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Project manager:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.projectManager}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="createdBy">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Created by:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.createdBy}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="lastUpdatedBy">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Last updated by:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.lastUpdatedBy}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="currentApprover">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Pending approval by:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {project.currentApprover}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <strong>Dates</strong>
            <Col>
              <Form.Group controlId="created_date">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Created date:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {utcStringDateTimeToLocalDateTimeFormat(project.createdDate)}
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="last_updated_date">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  Last updated date:
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  {utcStringDateTimeToLocalDateTimeFormat(project.lastUpdatedDate)}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProjectDetail;
