import { useInvestmentType } from '../../../hooks/useInvestmentType';
import { useInvestmentColumns } from './tableColumns/InvestmentColumns';
import { TableOptions } from './TableOptions';

export function InvestmentDashboard() {
  const { investmentType, isLoading } = useInvestmentType();
  const investColumns = useInvestmentColumns();

  return (
    <>
      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <TableOptions columns={investColumns} data={investmentType} loading={isLoading} />
      </div>
    </>
  );
}
