import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Layout } from './pages/Layout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './styles/App.css';
import AppRoutes from './AppRoutes';
import { ErrorPage } from './pages/ErrorPage';

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      Component: Layout,
      children: AppRoutes,
      errorElement: <ErrorPage />,
    },
  ]);

  const dacsaPrimaryColor = window
    .getComputedStyle(document.body)
    .getPropertyValue('--dacsa-color-primary');
  const dacsaSecondaryColor = window
    .getComputedStyle(document.body)
    .getPropertyValue('--dacsa-color-secondary');

  const theme = createTheme({
    palette: {
      primary: {
        main: dacsaPrimaryColor,
      },
      secondary: {
        main: dacsaSecondaryColor,
      },
      error: {
        main: '#FF0000',
      },
      ok: {
        main: '#008F39',
      },
      warning: {
        main: '#FFB600',
      },
      divider: dacsaPrimaryColor,
    },
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
