import { createContext, useContext, useState } from 'react';

export const InvestmentContext = createContext();

export const InvestmentProvider = ({ children }) => {
  const [reloadInvestment, setReloadInvestment] = useState(false);
  const [editInvestment, setEditInvestment] = useState(false);
  const [reloadFunctionalGroups, setReloadFunctionalGroups] = useState(false);
  const [investmentTypeUid, setInvestmentTypeUid] = useState('');
  const [loadSpinnerInvestmentType, setLoadSpinnerInvestmentType] = useState(false);
  const [modalResponseEditInvestment, setModalResponseEditInvestment] = useState(false);
  const [isErrorEditInvestment, setIsErrorEditInvestment] = useState(false);
  const [msgEditInvestment, setMsgEditInvestment] = useState('');

  return (
    <InvestmentContext.Provider
      value={{
        reloadInvestment,
        setReloadInvestment,
        editInvestment,
        setEditInvestment,
        reloadFunctionalGroups,
        setReloadFunctionalGroups,
        investmentTypeUid,
        setInvestmentTypeUid,
        loadSpinnerInvestmentType,
        setLoadSpinnerInvestmentType,
        modalResponseEditInvestment,
        setModalResponseEditInvestment,
        isErrorEditInvestment,
        setIsErrorEditInvestment,
        msgEditInvestment,
        setMsgEditInvestment,
      }}
    >
      {children}
    </InvestmentContext.Provider>
  );
};

export const useInvestmentContext = () => {
  const context = useContext(InvestmentContext);

  if (context === undefined)
    throw new Error('InvestmentContext must be used with InvestmentContext.Provider');

  return context;
};
