import { Button } from 'react-bootstrap';
import { animateScroll } from 'react-scroll';
import { useProjectContext } from '../context/ProjectContext';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';
import { ModalRemove } from './ModalRemove';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_PROJECT_URL } from '../../../config/ApiURLs';
import { useUserHaveRole } from '../../../hooks/useUserHaveRole';
import { useIsSameAsUserLogged } from '../../../hooks/useIsSameAsUserLogged';
import { useState } from 'react';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';

export function Buttons({ project }) {
  const {
    setShowAddComment,
    setEditProject,
    editProject,
    setReloadProject,
    setKey,
    loadSpinnerSave,
    setDuplicate,
  } = useProjectContext();
  const { TaskSuperUser, TaskAdmin, TaskUser } = appRoles;
  const statusProject = project.status;
  const isSuperUser = useUserHaveRole('Superusers');
  const isCurrentApprover = useIsSameAsUserLogged(project.currentApprover);
  const [loadSpinnerApproveReject, setLoadSpinnerApproveReject] = useState(false);
  const [loadUnfreeze, setLoadUnfreeze] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [isWarning, setIsWarning] = useState(false);

  const clickAddComment = () => {
    setKey('comments');
    setShowAddComment(true);
    animateScroll.scrollToBottom();
  };

  const clickEdit = () => {
    setModalResponse(false);
    setEditProject(true);
  };

  const clickCancel = () => {
    setModalResponse(false);
    setEditProject(false);
  };

  const clickUnfreeze = () => {
    if (statusProject === 'FROZEN') {
      manageAction('unfreeze');
      setDuplicate(false);
    }
  };

  const clickApprove = () => {
    if (statusProject === 'PENDING') manageAction('approve');
  };

  const clickReject = () => {
    if (statusProject === 'PENDING') manageAction('reject');
  };

  const manageAction = (action) => {
    setModalResponse(false);
    if (action !== 'approve' && action !== 'reject' && action !== 'unfreeze') return;

    if (action === 'approve' || action === 'reject') setLoadSpinnerApproveReject(true);
    if (action === 'unfreeze') setLoadUnfreeze(true);

    const url = API_PROJECT_URL + `/${project.uid}/manage-status?action=${action}`;
    axiosApiInstance
      .post(url)
      .then(() => {
        setReloadProject(true);
        if (action === 'approve' || action === 'reject') setLoadSpinnerApproveReject(false);
        if (action === 'unfreeze') setLoadUnfreeze(false);
        setMsg('The project status has been successfully changed!!');
        setIsError(false);
        setIsWarning(false);
        setModalResponse(true);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        if (action === 'approve' || action === 'reject') setLoadSpinnerApproveReject(false);
        if (action === 'unfreeze') setLoadUnfreeze(false);
        if (error.response && error.response.data.TypeResponse === 1) {
          setIsError(false);
          setIsWarning(true);
          setMsg(errorMessage);
        } else {
          setIsError(true);
          setIsWarning(false);
          setMsg(errorMessage);
        }
        setModalResponse(true);
      });
  };

  const handleModalCallbackButton = () => {
    setReloadProject(true);
  };

  const handleModalCallbackClose = () => {
    setReloadProject(true);
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <br />
      <Button className="botonComment" onClick={clickAddComment}>
        Add comment
      </Button>
      &nbsp;
      {editProject ? (
        <>
          {!loadSpinnerSave ? (
            <button className="botonComment btn btn-primary" type="submit" form="hook-form-edit">
              Save
            </button>
          ) : (
            <SpinnerButtons text="Save project..." textButton="Save"></SpinnerButtons>
          )}
          &nbsp;
          <Button className="botonComment" onClick={clickCancel}>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <AuthenticatedComponent roles={[TaskSuperUser, TaskAdmin]} exclude={[TaskUser]}>
            <Button className="botonComment" onClick={clickEdit}>
              Edit
            </Button>
            &nbsp;
          </AuthenticatedComponent>
          {statusProject === 'PENDING' && (isCurrentApprover || isSuperUser) && (
            <>
              {!loadSpinnerApproveReject ? (
                <>
                  <Button className="botonComment" onClick={clickApprove}>
                    Approve
                  </Button>
                  &nbsp;
                  <Button className="botonComment" onClick={clickReject}>
                    Reject
                  </Button>
                </>
              ) : (
                <>
                  <SpinnerButtons
                    text="Changing project status..."
                    textButton="Approve"
                  ></SpinnerButtons>
                  <Button className="botonComment">Reject</Button>
                </>
              )}
              &nbsp;
            </>
          )}
          {statusProject === 'FROZEN' && (
            <AuthenticatedComponent roles={[TaskSuperUser]}>
              {!loadUnfreeze ? (
                <>
                  <Button className="botonComment" onClick={clickUnfreeze}>
                    Unfreeze
                  </Button>
                  &nbsp;
                </>
              ) : (
                <SpinnerButtons text="Unfreeze project..." textButton="Unfreeze"></SpinnerButtons>
              )}
            </AuthenticatedComponent>
          )}
          <AuthenticatedComponent roles={[TaskSuperUser]}>
            <ModalRemove />
          </AuthenticatedComponent>
        </>
      )}
    </>
  );
}
