import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useCatalogContext } from '../CatalogContext';
import { API_CATALOG_URL } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { appRoles } from '../config/authConfig';
import { useUserHaveRole } from './useUserHaveRole';
import { InteractionStatus } from '@azure/msal-browser';

export const useCatalogs = () => {
  const { setCatalogs } = useCatalogContext();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const isSuperUser = useUserHaveRole(appRoles.TaskSuperUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const orderByNameAsc = (a, b) => (a.name < b.name ? -1 : 1);

    function filterPublicCatalogValues(catalogs) {
      const filteredCatalogs = catalogs.map((catalog) => {
        return {
          ...catalog,
          catalogValues: catalog.catalogValues.filter(
            (catalogValue) => catalogValue.isPublic || isSuperUser
          ),
        };
      });

      filteredCatalogs.sort(orderByNameAsc);
      filteredCatalogs.forEach((catalog) => catalog.catalogValues.sort(orderByNameAsc));
      setCatalogs(filteredCatalogs);
      setIsLoading(false);
    }

    if (inProgress === InteractionStatus.None && isAuthenticated)
      axiosApiInstance
        .get(API_CATALOG_URL)
        .then((response) => {
          const responseDetail = response.data;
          filterPublicCatalogValues(responseDetail.data);
        })
        .catch((error) => {
          const errorMessage = error.response ? error.response.data.Message : error.message;
          console.log('Error Feching Catalogs -> ' + errorMessage);
          setIsLoading(false);
        });
  }, [isAuthenticated, inProgress, isSuperUser, setCatalogs]);

  return isLoading;
};
