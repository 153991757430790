import { useState, useEffect, useCallback } from 'react';
import { useCatalogContext } from '../CatalogContext';
import { API_INVESTMENT_TYPE_URL } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { getCatalogValue } from '../services/CatalogService';
import { isArrayEmpty } from '../utils/CheckUtils';

export const useInvestmentType = () => {
  const [investmentType, setInvestmentType] = useState([]);
  const [error, setError] = useState('');
  const { catalogs } = useCatalogContext();
  const [isLoading, setIsloading] = useState(isArrayEmpty(investmentType));
  const addDescriptionInvestmentType = useCallback(
    (investmentTypes) => {
      let projectsWithDescription = [];
      investmentTypes.forEach((investmentType) => {
        const subtypeDescription = getCatalogValue(catalogs, 'subtype', investmentType.subtype);
        projectsWithDescription.push({ ...investmentType, subtypeDescription });
      });

      return projectsWithDescription;
    },
    [catalogs]
  );

  useEffect(() => {
    const fetchInvestmentTypeData = async () => {
      try {
        const { data } = await axiosApiInstance.get(API_INVESTMENT_TYPE_URL);
        const responseDetail = data;
        const investmentTypes = addDescriptionInvestmentType(responseDetail.data);
        setInvestmentType(investmentTypes);
        setIsloading(false);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setError('Error Fetching Investment Type ->' + errorMessage);
        setIsloading(false);
      }
    };

    if (!isArrayEmpty(catalogs)) {
      fetchInvestmentTypeData();
    }
  }, [catalogs, addDescriptionInvestmentType]);

  return { investmentType, isLoading, error };
};
