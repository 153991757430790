import { Skeleton } from '@mui/material';
import { Form, Row, Col } from 'react-bootstrap';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';

const SkeletonProjectDetail = () => {
  const sizeLabel = 4;
  const sizeCol = 8;
  const haveSubheadquarters = false;
  const showDepartment = true;
  const { TaskSuperUser, TaskAdmin } = appRoles;

  return (
    <>
      <Row>
        <Col xl={{ order: 'first' }} className="col-md-8">
          <Row>
            <Col>
              <Skeleton width={180} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="requestName">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="capexCode">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="type">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label>
                  <Skeleton width={180} sx={{ bgcolor: '#ffae00' }} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="headquarters">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              {showDepartment && (
                <Form.Group controlId="department">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    <Skeleton width={180} />
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    <Skeleton width={180} />
                  </Form.Label>
                </Form.Group>
              )}
              <Form.Group controlId="area">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="budget">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label>
                  <Skeleton width={95} sx={{ bgcolor: '#046a38' }} />
                </Form.Label>
                &nbsp;
                <Form.Label column xl={2}>
                  <Skeleton width={80} />
                </Form.Label>
              </Form.Group>
              <AuthenticatedComponent roles={[TaskSuperUser, TaskAdmin]}>
                <Form.Group as={Row} className="mb-3" controlId="selfApproval">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    <Skeleton width={180} />
                  </Form.Label>
                  <Col xl="2">
                    <Form.Label className="colorGrey" column xl={sizeLabel}>
                      <Skeleton width={20} />
                    </Form.Label>
                  </Col>
                </Form.Group>
              </AuthenticatedComponent>
            </Col>
            <Col>
              <Form.Group controlId="status">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="void1">
                <Form.Label column xl={sizeLabel}>
                  &nbsp;
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="subtype">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label>
                  <Skeleton width={180} sx={{ bgcolor: '#ffae00' }} />
                </Form.Label>
              </Form.Group>
              {haveSubheadquarters ? (
                <Form.Group controlId="subheadquarters">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    <Skeleton width={180} />
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    <Skeleton width={180} />
                  </Form.Label>
                </Form.Group>
              ) : (
                <Form.Group controlId="void2">
                  <Form.Label column xl={sizeLabel}>
                    &nbsp;
                  </Form.Label>
                </Form.Group>
              )}
              <Form.Group controlId="category">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="expectedEndDate">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
            </Col>
            <Form.Label>
              <Skeleton width={160} />
            </Form.Label>
            <Form.Label>
              <Skeleton width={1000} height={200} />
            </Form.Label>
          </Row>
        </Col>
        <div className="col-1 separador"></div>
        <Col xl={{ order: 'last' }}>
          <Row>
            <strong>
              <Skeleton width={180} />
            </strong>
            <Col>
              <Form.Group controlId="projectManager">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="createdBy">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="lastUpdatedBy">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="currentApprover">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <strong>
              <Skeleton width={180} />
            </strong>
            <Col>
              <Form.Group controlId="created_date">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="last_updated_date">
                <Form.Label className="colorGrey" column xl={sizeLabel}>
                  <Skeleton width={180} />
                </Form.Label>
                <Form.Label column xl={sizeCol}>
                  <Skeleton width={180} />
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SkeletonProjectDetail;
