import { useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Button } from 'react-bootstrap';
import { useInvestmentContext } from './context/InvestmentContext';
import { ModalResponse } from '../../../components/ModalResponse';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_INVESTMENT_TYPE_URL } from '../../../config/ApiURLs';
import { SpinnerButtons } from '../../../components/SpinnerButtons';

export const TableSelectOptions = ({ columns, data, title, loading }) => {
  const columnsDisplay = useMemo(() => columns, [columns]);
  const { setReloadFunctionalGroups, investmentTypeUid } = useInvestmentContext();
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);

  const handleModalCallbackButton = () => {
    setReloadFunctionalGroups(true);
  };

  const handleModalCallbackClose = () => {
    setReloadFunctionalGroups(true);
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          message={msg}
          buttonAccept={!isError}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <MaterialReactTable
        columns={columnsDisplay}
        data={data ?? []}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnResizing={true}
        enablePagination={false}
        enableHiding={false}
        muiTableContainerProps={{
          sx: { maxHeight: '200px' },
        }}
        state={{
          density: 'compact',
          showSkeletons: false,
          isLoading: loading,
          showProgressBars: false,
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            color: theme.palette.text.secondary,
          }),
        }}
        muiTableBodyCellProps={{
          sx: (theme) => ({
            color: theme.palette.text.secondary,
          }),
        }}
        enableRowSelection
        renderTopToolbarCustomActions={({ table }) => {
          const handleDelete = () => {
            setLoadSpinner(true);
            setModalResponse(false);
            table.getSelectedRowModel().flatRows.forEach((row) => {
              axiosApiInstance
                .delete(
                  API_INVESTMENT_TYPE_URL +
                    `/${investmentTypeUid}/functional-groups/${row.original.uid}`
                )
                .then(() => {
                  setReloadFunctionalGroups(true);
                  setIsError(false);
                  setMsg('The viewer has been removed correctly!!');
                  setLoadSpinner(false);
                  setModalResponse(true);
                })
                .catch((error) => {
                  const errorMessage = error.response ? error.response.data.Message : error.message;
                  setIsError(true);
                  setMsg(errorMessage);
                  setLoadSpinner(false);
                  setModalResponse(true);
                });
            });
          };

          return (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              {loadSpinner ? (
                <SpinnerButtons
                  text="Delete group of approver..."
                  textButton="Delete"
                ></SpinnerButtons>
              ) : (
                <Button
                  color="error"
                  disabled={table.getSelectedRowModel().flatRows.length === 0}
                  onClick={handleDelete}
                  variant="contained"
                  className="botonTable"
                >
                  Delete
                </Button>
              )}
            </div>
          );
        }}
        enableSorting={false}
      />
    </>
  );
};
