import { Form, Col, Row, Nav, Container } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useCatalogContext } from '../../../CatalogContext';
import { getCatalog } from '../../../services/CatalogService';
import { useRef, useState } from 'react';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_INVESTMENT_TYPE_URL } from '../../../config/ApiURLs';
import { ModalResponse } from '../../../components/ModalResponse';
import { SpinnerButtons } from '../../../components/SpinnerButtons';

export function NewInvestmentType() {
  const sizeLabel = 3;
  const sizeCol = 6;

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { catalogs } = useCatalogContext();

  const catalogType = getCatalog(catalogs, 'type');
  const catalogSubtype = getCatalog(catalogs, 'subtype');
  const [setType] = useState('');
  const [setSubtype] = useState('');
  const [subtypeList, setSubtypeList] = useState([]);
  const selectInputRefSubtype = useRef();
  const navigate = useNavigate();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [responseDetail, setResponseDetail] = useState('');

  const onClear = (ref) => {
    ref.current.clearValue();
  };

  const handleTypeChange = (obj) => {
    onClear(selectInputRefSubtype);
    if (obj === null) {
      setSubtypeList([]);
      setType('');
    } else {
      let subtypes = [];
      catalogSubtype.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === obj.code) {
          subtypes.push(valueCatalog);
        }
        return subtypes;
      });

      setSubtypeList(subtypes);
      setType(obj.code);
    }
  };

  const handleSubTypeChange = (obj) => {
    if (obj !== null) {
      setSubtype(obj.name);
    }
  };

  const onSubmit = (data) => {
    setLoadSpinner(true);
    axiosApiInstance
      .post(API_INVESTMENT_TYPE_URL, data)
      .then((res) => {
        setResponseDetail(res.data);
        setIsError(false);
        setMsg('Added a new type of investment correctly!!');
        setLoadSpinner(false);
        setModalResponse(true);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        this.setState({ errorMessage: errorMessage });
        setIsError(true);
        setMsg(errorMessage);
        setLoadSpinner(false);
        setModalResponse(true);
      });
  };

  const handleModalCallbackButton = () => {
    navigate('/investmenttype/' + responseDetail.data);
  };

  const handleModalCallbackClose = () => {
    navigate('/investmenttype');
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          message={msg}
          buttonAccept={!isError}
          callbackButton={handleModalCallbackButton}
          callbackClose={handleModalCallbackClose}
        />
      )}
      <Container>
        <br />
        <Col>
          <Row>
            <Col className="col-md-8">
              <h2 className="colorDarkGrey">Investment Type - Create</h2>
            </Col>
            <Col className="alignEnd">
              {loadSpinner ? (
                <SpinnerButtons
                  text="Creating type of investment..."
                  textButton="Create"
                ></SpinnerButtons>
              ) : (
                <>
                  <button className="botonComment btn btn-primary" type="submit" form="hook-form">
                    Create
                  </button>
                  &nbsp;
                </>
              )}
              <Nav.Link className="botonCancel btn btn-primary" as={NavLink} to={'/investmenttype'}>
                Cancel
              </Nav.Link>
            </Col>
          </Row>
        </Col>
        <Form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
          <Col xl={{ order: 'first' }} className="col-md-8">
            <Row>
              <Col>
                <strong>Details</strong>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Row} className="mb-3" controlId="name">
                <Form.Label column xl={sizeLabel}>
                  Name:
                </Form.Label>
                <Col xl={9}>
                  <Form.Control
                    type="text"
                    className="investementTypeName indent-1"
                    placeholder="Enter investment type name"
                    {...register('name', { required: true })}
                  />
                  {errors.name && (
                    <Form.Text className="text-danger">The name field is required</Form.Text>
                  )}
                </Col>
              </Form.Group>
            </Row>
            <Form.Group as={Row} className="mb-3" controlId="type">
              <Form.Label column xl={sizeLabel}>
                Type:
              </Form.Label>
              <Col xl={sizeCol}>
                <Controller
                  control={control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={catalogType.catalogValues}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value ? catalogType.catalogValues.find((x) => x.code === value) : value
                      }
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                        handleTypeChange(option);
                      }}
                      ref={ref}
                    />
                  )}
                />
                {errors.type && (
                  <Form.Text className="text-danger">The type field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="subtype">
              <Form.Label column xl={sizeLabel}>
                Subtype:
              </Form.Label>
              <Col xl={sizeCol}>
                <Controller
                  control={control}
                  name="subtype"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={subtypeList}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={value ? subtypeList.find((x) => x.code === value) : value}
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                        handleSubTypeChange(option);
                      }}
                      ref={selectInputRefSubtype}
                    />
                  )}
                />
                {errors.subtype && (
                  <Form.Text className="text-danger">The subtype field is required</Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="minAmount">
              <Form.Label column xl={sizeLabel}>
                Min Amount:
              </Form.Label>
              <Col xl={sizeCol}>
                <Form.Control
                  type="number"
                  placeholder="Enter min amount"
                  {...register('minAmount', { required: true })}
                />
                {errors.budget && (
                  <Form.Text className="text-danger">The min amount field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="maxAmount">
              <Form.Label column xl={sizeLabel}>
                Max Amount:
              </Form.Label>
              <Col xl={sizeCol}>
                <Form.Control
                  type="number"
                  placeholder="Enter max amount"
                  {...register('maxAmount', { required: true })}
                />
                {errors.budget && (
                  <Form.Text className="text-danger">The max amount field is required</Form.Text>
                )}
              </Col>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3" controlId="requiresAttached">
                  <Form.Label column xl={sizeLabel}>
                    Requires attachment:
                  </Form.Label>
                  <Col xl="2" className="checkIndent-1">
                    <Form.Check {...register('requiresAttached')} type="checkbox" />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Form>
      </Container>
    </>
  );
}
