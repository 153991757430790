import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { allowedActions } from '../../config/RemoteManageActions';
import { isVariableEmpty } from '../../utils/CheckUtils';
import { useEffect, useState } from 'react';
import { SpinnerButtons } from '../../components/SpinnerButtons';
import axiosApiInstance from '../../config/axiosApiInstance';
import { API_PROJECT_URL } from '../../config/ApiURLs';
import { ModalResponse } from '../../components/ModalResponse';

export function ManageStatus() {
  const { projectUid } = useParams();
  const [searchParams] = useSearchParams();
  const [load, setLoad] = useState(true);
  const [msg, setMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [projectCodeWarning, setProjectCodeWarning] = useState('');

  let action;
  let projectcode;
  const navigate = useNavigate();

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param === 'action' && allowedActions.some((action) => value === action)) action = value;
    if (param === 'projectcode') projectcode = value;
  }

  useEffect(() => {
    if (isVariableEmpty(action)) return <> Action Not supported'</>;

    if (action === 'approve' || action === 'reject') {
      axiosApiInstance
        .post(API_PROJECT_URL + `/${projectUid}/manage-status?action=${action}`)
        .then(() => {
          setLoad(false);
          setIsError(false);
          setIsWarning(false);
          setMsg('The project status has been successfully changed!!');
          setModalResponse(true);
        })
        .catch((error) => {
          setLoad(false);
          const errorMessage = error.response ? error.response.data.Message : error.message;
          if (error.response && error.response.data.TypeResponse === 1) {
            setIsError(false);
            setIsWarning(true);
            setMsg(errorMessage);
            setProjectCodeWarning(errorMessage.split('-')[0]);
          } else {
            setIsError(true);
            setIsWarning(false);
            setMsg(errorMessage);
          }
          setModalResponse(true);
        });
    }
  }, [action, projectUid, projectcode]);

  const handleModalCallbackClose = () => {
    navigate(`/dashboard`);
  };

  const handleModalCallbackButton = () => {
    if (projectCodeWarning !== '') {
      navigate(`/project/${projectCodeWarning}`);
    } else {
      navigate(`/project/${projectcode}`);
    }
  };

  return (
    <>
      {load && <SpinnerButtons text="Processing..." />}
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={handleModalCallbackClose}
        />
      )}
    </>
  );
}
