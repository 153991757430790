import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import { Skeleton } from '@mui/material';
import { SkeletonInvestmentTypeDetail } from './SkeletonInvestmentTypeDetail';
import { SkeletonTabMenuInvestment } from './SkeletonTabMenuInvestment';

export function SkeletonInvestmentTypeLayout() {
  return (
    <>
      <Container>
        <br />
        <Col>
          <Row>
            <Col className="col-md-8">
              <h2 className="colorDarkGrey">Investment Type</h2>
            </Col>
            <Col className="alignEnd">
              <Button className="botonComment">
                <Skeleton width={180} />
              </Button>
              &nbsp;
              <Nav.Link className="botonCancel btn btn-primary">
                <Skeleton width={180} />
              </Nav.Link>
            </Col>
          </Row>
        </Col>
        <SkeletonInvestmentTypeDetail />
        <hr />
        <SkeletonTabMenuInvestment />
      </Container>
    </>
  );
}
