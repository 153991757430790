import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { useCatalogContext } from '../../CatalogContext';

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const { resetCatalogs } = useCatalogContext();

  const handleLogout = () => {
    instance.logout({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
    });
    resetCatalogs();
  };

  return (
    <>
      <Button variant="link" className="colorWhite" onClick={handleLogout}>
        Sign out
      </Button>
    </>
  );
};
