import { TableStandardOptions } from '../../../components/TableStandardOptions';
import { useProjectColumns } from './tableColumns/ProjectColumns';
import { useProjectsCreatedByUser } from '../../../hooks/useProjectsCreatedByUser';
import { useProjectsPendingApprovalByUser } from '../../../hooks/useProjectsPendingApprovalByUser';
import { useProjectsInterestedForUser } from '../../../hooks/useProjectsInterestedForUser';

export function UserDashboard() {
  const { projects: projectsInterestedForUser, isLoading: isLoadingProjectsInterestedForUser } =
    useProjectsInterestedForUser();
  const { projects: projectsCreatedByUSer, isLoading: isLoadingProjectsCreatedByUser } =
    useProjectsCreatedByUser();
  const { projects: projectsPendigApprovalByUSer, isLoading: isLoadingProjectsApprovalByUser } =
    useProjectsPendingApprovalByUser();
  const projectColumns = useProjectColumns();

  return (
    <>
      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <TableStandardOptions
          columns={projectColumns}
          data={projectsPendigApprovalByUSer}
          title={'Pending Approvement'}
          loading={isLoadingProjectsApprovalByUser}
        />
      </div>

      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <TableStandardOptions
          columns={projectColumns}
          data={projectsCreatedByUSer}
          title={'My own Projects'}
          loading={isLoadingProjectsCreatedByUser}
        />
      </div>

      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <TableStandardOptions
          columns={projectColumns}
          data={projectsInterestedForUser}
          title={'Projects following'}
          loading={isLoadingProjectsInterestedForUser}
        />
      </div>
    </>
  );
}
