import { useEffect, useState } from 'react';
import { Form, Row, Col, Badge } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import CampoFecha from './CampoFecha';
import { useCatalogContext } from '../../../CatalogContext';
import { getCatalog } from '../../../services/CatalogService';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_PROJECT_URL } from '../../../config/ApiURLs';
import { useProjectContext } from '../context/ProjectContext';
import { SelectProjectManager } from './SelectProjectManager';
import { ModalResponse } from '../../../components/ModalResponse';
import { FormCatalogSelect } from '../../../components/FormCatalogSelect';

const EditProject = ({ project }) => {
  const { catalogs } = useCatalogContext();
  const sizeLabel = 4;
  const sizeCol = 8;
  const tamanyoSelects = 6;
  const catalogArea = getCatalog(catalogs, 'area');
  const catalogCategory = getCatalog(catalogs, 'category');
  const catalogDepartment = getCatalog(catalogs, 'department');
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [isWarning, setIsWarning] = useState(false);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: project.name,
      type: project.type,
      subtype: project.subtype,
      headquarters: project.headquartersId,
      subheadquarters: project.subheadquartersId,
      area: project.areaId,
      category: project.categoryId,
      budget: project.budget,
      badge: project.badgeId,
      startDate: project.startDate,
      expectedEndDate: project.expectedEndDate,
      summary: project.summary,
      projectManager: project.projectManager,
      department: project.departmentId,
      code: project.code,
      visibility: project.visibility,
      typeofproject: project.typeOfProjectId,
    },
  });

  const [haveSubheadquarters, sethaveSubheadquarters] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);
  const { TaskSuperUser } = appRoles;
  const { setEditProject, setReloadProject, setLoadSpinnerSave } = useProjectContext();
  const showTypeofproject = project.type === 'SPECIALS';

  useEffect(() => {
    if (typeof project.subheadquartersId !== 'undefined' && project.subheadquartersId !== null) {
      sethaveSubheadquarters(true);
    }

    if (project.type === 'CORPORATE') {
      setShowDepartment(true);
    }
  }, [project, sethaveSubheadquarters, setShowDepartment]);

  const onSubmit = (data) => {
    setIsError(false);
    setLoadSpinnerSave(true);
    axiosApiInstance
      .put(API_PROJECT_URL, data)
      .then(() => {
        setLoadSpinnerSave(false);
        setMsg('Project edited successfully!');
        setIsError(false);
        setIsWarning(false);
        setModalResponse(true);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setLoadSpinnerSave(false);
        if (error.response && error.response.data.TypeResponse === 1) {
          setIsError(false);
          setIsWarning(true);
          setMsg(errorMessage);
        } else {
          setIsError(true);
          setIsWarning(false);
          setMsg(errorMessage);
        }
        setModalResponse(true);
      });
  };

  if (!catalogs || catalogs.length === 0) {
    return <div>Cargando</div>;
  }

  const handleModalCallbackButton = () => {
    setEditProject(false);
    setReloadProject(true);
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackButton}
        />
      )}
      <br />
      <Form id="hook-form-edit" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xl={{ order: 'first' }} className="col-md-8">
            <Row>
              <Col>
                <strong>Details</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-1" controlId="name">
                  <Form.Label column xl={sizeLabel}>
                    Name:
                  </Form.Label>
                  <Col xl={sizeCol}>
                    <Form.Control
                      type="text"
                      placeholder="Enter project name"
                      {...register('name', { required: true })}
                    />
                    {errors.name && (
                      <Form.Text className="text-danger">The name field is required</Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    CAPEX Code:
                  </Form.Label>
                  <Form.Label column xl={7}>
                    {project.capexCode}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    Type:
                  </Form.Label>
                  &nbsp;&nbsp;
                  <Badge className="dacsa-yellow">{project.type}</Badge>
                </Form.Group>
                <Form.Group as={Row} className="mb-1">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    Headquarters:
                  </Form.Label>
                  <Form.Label column xl={7}>
                    {project.headquartersDescription}
                  </Form.Label>
                </Form.Group>
                {showDepartment && (
                  <Form.Group as={Row} className="mb-1" controlId="department">
                    <Form.Label column xl={sizeLabel}>
                      Department:
                    </Form.Label>
                    <Col xl={tamanyoSelects}>
                      <Controller
                        control={control}
                        name="department"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            placeholder="Select value"
                            isClearable
                            options={catalogDepartment.catalogValues}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            value={
                              value
                                ? catalogDepartment.catalogValues.find((x) => x.code === value)
                                : value
                            }
                            onChange={(option) => onChange(option ? option.code : option)}
                            ref={ref}
                          />
                        )}
                      />
                    </Col>
                  </Form.Group>
                )}
                {showTypeofproject && (
                  <FormCatalogSelect
                    as={Row}
                    catalogType={'typeofproject'}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                    className="mb-1"
                    sizeCol={tamanyoSelects}
                    sizeLabel={sizeLabel}
                  />
                )}

                <Form.Group as={Row} className="mb-1" controlId="area">
                  <Form.Label column xl={sizeLabel}>
                    Area:
                  </Form.Label>
                  <Col xl={tamanyoSelects}>
                    <Controller
                      control={control}
                      name="area"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          name={name}
                          placeholder="Select value"
                          isClearable
                          options={catalogArea.catalogValues}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.code}
                          value={
                            value ? catalogArea.catalogValues.find((x) => x.code === value) : value
                          }
                          onChange={(option) => onChange(option ? option.code : option)}
                          ref={ref}
                        />
                      )}
                    />
                    {errors.area && (
                      <Form.Text className="text-danger">The area field is required</Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    Budget:
                  </Form.Label>
                  &nbsp;&nbsp;
                  <Badge className="dacsa-green fontSize-18">{project.budget}</Badge>&nbsp;
                  <Form.Label column xl={2}>
                    {project.badgeId}
                  </Form.Label>
                </Form.Group>
                <AuthenticatedComponent roles={[TaskSuperUser]}>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-1" controlId="selfApproval">
                        <Form.Label column xl={sizeLabel}>
                          Self approved:
                        </Form.Label>
                        <Col xl="2" className="checkIndent-2">
                          <Form.Check type="checkbox" readOnly />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </AuthenticatedComponent>
              </Col>
              <Col>
                <Form.Group as={Row} className="mb-1">
                  <Form.Label column xl={sizeLabel}>
                    Status:
                  </Form.Label>
                  <Form.Label column xl={7}>
                    {project.status}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mb-1">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    &nbsp;
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label className="colorGrey" column xl={sizeLabel}>
                    Subtype:
                  </Form.Label>
                  &nbsp;&nbsp;
                  <Badge className="dacsa-yellow">{project.subtypeDescription}</Badge>
                </Form.Group>

                {haveSubheadquarters ? (
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column xl={sizeLabel}>
                      Sub-headquarters:
                    </Form.Label>
                    <Form.Label column xl={sizeCol}>
                      {project.subheadquartersId}
                    </Form.Label>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label className="colorGrey" column xl={sizeLabel}>
                      &nbsp;&nbsp;
                    </Form.Label>
                  </Form.Group>
                )}
                <Form.Group as={Row} className="mb-1" controlId="category">
                  <Form.Label column xl={sizeLabel}>
                    Category:
                  </Form.Label>
                  <Col xl={tamanyoSelects}>
                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          name={name}
                          placeholder="Select value"
                          isClearable
                          options={catalogCategory.catalogValues}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.code}
                          value={
                            value
                              ? catalogCategory.catalogValues.find((x) => x.code === value)
                              : value
                          }
                          onChange={(option) => onChange(option ? option.code : option)}
                          ref={ref}
                        />
                      )}
                    />
                    {errors.category && (
                      <Form.Text className="text-danger">The category field is required</Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="startDate">
                  <Form.Label column xl={sizeLabel}>
                    Start date:
                  </Form.Label>
                  <Col sm={tamanyoSelects}>
                    <CampoFecha name="startDate" control={control} />
                    {errors.startDate && (
                      <Form.Text className="text-danger">
                        The start date field is required
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="expectedEndDate">
                  <Form.Label column xl={sizeLabel}>
                    Expected end date:
                  </Form.Label>
                  <Col sm={tamanyoSelects}>
                    <CampoFecha name="expectedEndDate" control={control} />
                    {errors.expectedEndDate && (
                      <Form.Text className="text-danger">
                        The expected end date field is required
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Label column xl={sizeLabel}>
                Summary:
              </Form.Label>
              <Form.Control
                className="colorGrey indent-1"
                as="textarea"
                placeholder="Project summary"
                cols="40"
                rows="5"
                {...register('summary', { required: true })}
              />
              {errors.summary && (
                <Form.Text className="text-danger">The summary field is required</Form.Text>
              )}
            </Row>
          </Col>
          <div className="col-1 separador"></div>
          <Col xl={{ order: 'last' }}>
            <Row>
              <strong>People</strong>
              <Col>
                <Form.Group as={Row} className="mb-1" controlId="projectManager">
                  <Form.Label column xl={sizeLabel}>
                    Project manager:
                  </Form.Label>
                  <Col xl={6}>
                    <Controller
                      control={control}
                      name="projectManager"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <SelectProjectManager value={value} onChange={onChange} name={name} />
                      )}
                    />
                    {errors.projectManager && (
                      <Form.Text className="text-danger">
                        The project manager field is required
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="createdBy">
                  <Form.Label column xl={sizeLabel}>
                    Created by:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.createdBy}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="lastUpdatedBy">
                  <Form.Label column xl={sizeLabel}>
                    Last updated by:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.lastUpdatedBy}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="currentApprover">
                  <Form.Label column xl={sizeLabel}>
                    Pending approval by:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.currentApprover}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <strong>Dates</strong>
              <Col>
                <Form.Group as={Row} className="mb-1" controlId="created_date">
                  <Form.Label column xl={sizeLabel}>
                    Created date:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.createdDate}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="last_updated_date">
                  <Form.Label column xl={sizeLabel}>
                    Last updated date:
                  </Form.Label>
                  <Form.Label column xl={sizeCol}>
                    {project.lastUpdateDate}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditProject;
