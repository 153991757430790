import { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { StandardInitialState } from './config/TableConfigs';

export const TableStandardOptions = ({ columns, data, title, loading }) => {
  const columnsDisplay = useMemo(() => columns, [columns]);

  return (
    <MaterialReactTable
      columns={columnsDisplay}
      data={data ?? []}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableColumnResizing={true}
      initialState={StandardInitialState}
      state={{
        density: 'compact',
        showSkeletons: false,
        isLoading: loading,
        showProgressBars: false,
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [3, 5, 10, 15, 20, 25, 30, 50, 100],
      }}
      renderTopToolbarCustomActions={() => <h4 className="colorDarkGrey">{title}</h4>}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          color: theme.palette.text.secondary,
        }),
      }}
      muiTableBodyCellProps={{
        sx: (theme) => ({
          color: theme.palette.text.secondary,
        }),
      }}
    />
  );
};
