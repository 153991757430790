import { useProjects } from '../../../hooks/useProjects';
import { TableFullOptions } from '../../../components/TableFullOptions';
import { useSuperUserProjectColumns } from './tableColumns/SuperUserProjectColumns';

export function SuperUserDashboard() {
  const { projects, isLoading } = useProjects();
  const superUserProjectColumns = useSuperUserProjectColumns();

  return (
    <>
      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <TableFullOptions columns={superUserProjectColumns} data={projects} loading={isLoading} />
      </div>
    </>
  );
}
