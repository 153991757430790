export function useApproversViewersColumns() {
  const ApproversViewersColumns = [
    {
      accessorKey: 'code',
      header: 'Code',
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
  ];

  return ApproversViewersColumns;
}
