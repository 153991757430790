import Select from 'react-select';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useApproversViewersColumns } from './tableColumns/ApproversViewersColumns';
import { useApprovers } from '../../../hooks/useApprovers';
import { useFunctionalGroups } from '../../../hooks/useFunctionalGroups';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { useInvestmentContext } from './context/InvestmentContext';
import { API_INVESTMENT_TYPE_URL } from '../../../config/ApiURLs';
import { useRef, useState } from 'react';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';
import { TableMoveOptions } from './TableMoveOptions';

export function Approvers({ investmentType }) {
  const approversViewersColumns = useApproversViewersColumns();
  const sizeLabel = 2;
  const { control } = useForm();
  const { approvers } = useApprovers();
  const { functionalGroups } = useFunctionalGroups(investmentType.uid, 'APPROVERS');
  const { investmentTypeUid, setReloadFunctionalGroups } = useInvestmentContext();
  const selectInputRefGroup = useRef();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');

  const clickAdd = () => {
    setLoadSpinner(true);
    setModalResponse(false);

    if (selectInputRefGroup.current.props.value !== null) {
      const group = {
        groupType: 'APPROVERS',
        groupCode: selectInputRefGroup.current.props.value.code,
        orderId: functionalGroups.length + 1,
      };
      axiosApiInstance
        .post(API_INVESTMENT_TYPE_URL + `/${investmentTypeUid}/functional-groups`, group)
        .then(() => {
          setReloadFunctionalGroups(true);
          setIsError(false);
          setMsg('Added a group of approvers correctly!!');
          setLoadSpinner(false);
          setModalResponse(true);
        })
        .catch((error) => {
          const errorMessage = error.response ? error.response.data.Message : error.message;
          setIsError(true);
          setMsg(errorMessage);
          setLoadSpinner(false);
          setModalResponse(true);
        });
    } else {
      setLoadSpinner(false);
    }
  };

  const handleModalCallbackButton = () => {
    setReloadFunctionalGroups(true);
  };

  const handleModalCallbackClose = () => {
    setReloadFunctionalGroups(true);
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          message={msg}
          buttonAccept={!isError}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <Form>
        <Form.Group as={Row} className="mb-1" controlId="group">
          <Form.Label column xl={sizeLabel}>
            Group:
          </Form.Label>
          <Col xl={6}>
            <Controller
              control={control}
              name="group"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  className="group"
                  name={name}
                  placeholder="Select value"
                  isClearable
                  options={approvers}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.code}
                  value={value ? approvers.find((x) => x.code === value) : value}
                  onChange={(option) => onChange(option ? option.name : option)}
                  ref={selectInputRefGroup}
                />
              )}
            />
          </Col>
          <Col>
            {loadSpinner ? (
              <SpinnerButtons text="Add group of approver..." textButton="Add"></SpinnerButtons>
            ) : (
              <Button className="botonComment" onClick={clickAdd}>
                Add
              </Button>
            )}
          </Col>
        </Form.Group>
      </Form>
      <Row>
        <Col>
          <strong>Current list</strong>
        </Col>
      </Row>
      <div className="d-flex flex-column bd-highlight mb-3 colorDarkGrey">
        <TableMoveOptions columns={approversViewersColumns} data={functionalGroups} />
      </div>
    </>
  );
}
