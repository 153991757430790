import { useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { SignOutButton } from './authentication/SignOutButton';
import { useUserName } from '../hooks/useUsername';
import { AuthenticatedComponent } from './authentication/AuthenticatedComponent';
import { appRoles } from '../config/authConfig';

export function NavMenu() {
  const isAuthenticated = useIsAuthenticated();
  const userName = useUserName();

  return (
    <Navbar collapseOnSelect bg="light" expand="sm" className="navbar-main" sticky="top">
      <Container fluid>
        <strong className="colorWhite title">DACSA Capex Approval</strong>&nbsp;&nbsp;&nbsp;
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="width"></div>
          <Nav className="me-auto">
            <AuthenticatedComponent roles={appRoles.AllRoles}>
              <Nav.Item>
                <Nav.Link className="colorWhite shadowGreen" as={NavLink} to="/dashboard">
                  DASHBOARD
                </Nav.Link>
              </Nav.Item>
              <AuthenticatedComponent roles={appRoles.ElevatedRoles}>
                <NavDropdown title="ADMINISTRATION" id="nav-dropdown" className="dropdownMenu">
                  <NavDropdown.Item eventKey="4.1" as={NavLink} to="/investmenttype">
                    Investment Type
                  </NavDropdown.Item>
                </NavDropdown>
              </AuthenticatedComponent>
            </AuthenticatedComponent>
          </Nav>
          <Nav>
            <Nav.Item>
              {isAuthenticated ? (
                <>
                  <Nav>
                    <Navbar.Text className="colorWhite">{userName}</Navbar.Text>
                    <SignOutButton />
                  </Nav>
                </>
              ) : (
                <p />
              )}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
