import moment from 'moment';
import React from 'react';

export function Footer() {
  return (
    <>
      <div id="footerDacsa">
        <div>Copyright © {moment().year()} Grupo Dacsa | Todos los derechos reservados.</div>
      </div>
    </>
  );
}
