import { createContext, useContext, useEffect, useReducer } from 'react';

export const CatalogContext = createContext();

let reducer = (catalogs, newCatalogs) => {
  if (newCatalogs === null) {
    sessionStorage.removeItem('localCatalogs');
    return [];
  }
  return [...newCatalogs];
};
const localState = JSON.parse(sessionStorage.getItem('localCatalogs'));

export const CatalogProvider = ({ children }) => {
  const [catalogs, setCatalogs] = useReducer(reducer, localState || []);

  const resetCatalogs = () => {
    setCatalogs(null);
  };
  useEffect(() => {
    sessionStorage.setItem('localCatalogs', JSON.stringify(catalogs));
  }, [catalogs]);

  return (
    <CatalogContext.Provider value={{ catalogs, setCatalogs, resetCatalogs }}>
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalogContext = () => {
  const context = useContext(CatalogContext);

  if (context === undefined)
    throw new Error('CatalogContext must be used with CatalogContext.Provider');

  return context;
};
