import { useState, useEffect } from 'react';
import { API_PROJECT_URL } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { useProjectContext } from '../pages/Project/context/ProjectContext';
import { isObjectEmpty } from '../utils/CheckUtils';

export const useProjectComments = (uidProject) => {
  const [projectComments, setProjectComments] = useState({});
  const { reloadComments, setReloadComments } = useProjectContext();

  const isProjectCommentsLoading = isObjectEmpty(projectComments);

  useEffect(() => {
    const fetchProjectCommentsData = async () => {
      try {
        const { data } = await axiosApiInstance.get(API_PROJECT_URL + `/${uidProject}/comments`);
        const responseDetail = data;
        setProjectComments(responseDetail.data);
        setReloadComments(false);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        console.log('Error Feching Project Comments -> ' + errorMessage);
        setReloadComments(false);
      }
    };

    if (isProjectCommentsLoading || reloadComments) {
      fetchProjectCommentsData();
    }
  }, [isProjectCommentsLoading, uidProject, reloadComments, setReloadComments]);

  return { projectComments, setReloadComments };
};
