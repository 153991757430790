import { AuthenticatedComponent } from '../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../config/authConfig';
import { SuperUserDashboard } from './components/SuperUserDashboard';
import { UserDashboard } from './components/UserDashboard';
import { Col, Row, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export function Dashboard() {
  const { TaskSuperUser, TaskAdmin, TaskUser } = appRoles;

  return (
    <div>
      <CabeceraDashboard />
      <AuthenticatedComponent roles={[TaskUser]}>
        <UserDashboard />
      </AuthenticatedComponent>
      <AuthenticatedComponent roles={[TaskAdmin, TaskSuperUser]}>
        <SuperUserDashboard />
      </AuthenticatedComponent>
    </div>
  );
}

function CabeceraDashboard() {
  const { TaskAdmin } = appRoles;

  return (
    <>
      <br />
      <Col>
        <Row>
          <Col className="col-md-8">
            <h2 id="tableLabel" className="colorDarkGrey">
              Projects Dashboard
            </h2>
          </Col>
          <Col className="indent-1">
            <AuthenticatedComponent exclude={[TaskAdmin]}>
              <Nav.Link className="botonNewProject btn btn-primary" as={NavLink} to={'/newproject'}>
                New Project
              </Nav.Link>
            </AuthenticatedComponent>
          </Col>
        </Row>
        <br />
      </Col>
    </>
  );
}
