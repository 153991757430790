import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';

export function ModalResponse({
  showModal,
  isError,
  isWarning,
  message,
  callbackClose,
  buttonAccept,
  callbackButton,
}) {
  const [show, setShow] = useState(showModal);

  useEffect(() => {
    setShow(showModal);
  }, [showModal, isWarning]);

  const handleClose = () => {
    if (callbackClose) callbackClose();
    setShow(false);
  };

  const handleButton = () => {
    if (callbackButton) callbackButton();
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        size={isError || isWarning ? undefined : 'sm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isError && 'Error!'}
            {isWarning && 'Warning!'}
            {!isError && !isWarning && 'Success!'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isError && (
            <p>
              <ErrorIcon color="error" /> An error has occurred:
              <br />
              <b>"{message}"</b>
            </p>
          )}
          {isWarning && (
            <p>
              <WarningIcon color="warning" /> A non-blocking error has occurred:
              <br />
              <b>"{message}"</b>
            </p>
          )}
          {!isError && !isWarning && (
            <>
              <p>
                <TaskAltIcon color="ok" />{' '}
                {message ? message : 'Your request has been successfully processed.'}
              </p>
            </>
          )}
        </Modal.Body>
        {buttonAccept && (
          <Modal.Footer>
            <Button className="botonAcceptModal" onClick={handleButton}>
              Accept
            </Button>
          </Modal.Footer>
        )}
        {isWarning && (
          <Modal.Footer>
            <Button className="botonAcceptModal" onClick={handleButton}>
              Accept
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
