import { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Button } from 'react-bootstrap';
import { useInvestmentContext } from './context/InvestmentContext';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_INVESTMENT_TYPE_URL } from '../../../config/ApiURLs';
import { ModalResponse } from '../../../components/ModalResponse';
import { SpinnerButtons } from '../../../components/SpinnerButtons';

export const TableMoveOptions = ({ columns, data, title, loading }) => {
  const columnsDisplay = useMemo(() => columns, [columns]);
  const [dataMove, setDataMove] = useState('');
  const { setReloadFunctionalGroups, investmentTypeUid } = useInvestmentContext();
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [loadSpinnerMove, setLoadSpinnerMove] = useState(false);

  const handleModalCallbackButton = () => {
    setReloadFunctionalGroups(true);
  };

  const handleModalCallbackClose = () => {
    setReloadFunctionalGroups(true);
  };

  useEffect(() => {
    if (typeof data !== 'undefined') setDataMove(data);
  }, [setDataMove, data]);

  const handleSaveChanges = () => {
    setLoadSpinnerMove(true);
    setModalResponse(false);
    const groups = [];
    dataMove.forEach((data, i) => {
      let group = {
        groupType: data.groupType,
        groupCode: data.code,
        orderId: i + 1,
      };
      groups.push(group);
    });
    axiosApiInstance
      .post(API_INVESTMENT_TYPE_URL + `/${investmentTypeUid}/functional-groups/reorder`, groups)
      .then(() => {
        setIsError(false);
        setMsg('The order of approvers has been changed correctly!!');
        setLoadSpinnerMove(false);
        setModalResponse(true);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setIsError(true);
        setMsg(errorMessage);
        setLoadSpinner(false);
        setModalResponse(true);
      });
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          message={msg}
          buttonAccept={!isError}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <MaterialReactTable
        columns={columnsDisplay}
        data={dataMove ?? []}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnResizing={true}
        enablePagination={false}
        enableHiding={false}
        muiTableContainerProps={{
          sx: { maxHeight: '200px' },
        }}
        state={{
          density: 'compact',
          showSkeletons: false,
          isLoading: loading,
          showProgressBars: false,
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            color: theme.palette.text.secondary,
          }),
        }}
        muiTableBodyCellProps={{
          sx: (theme) => ({
            color: theme.palette.text.secondary,
          }),
        }}
        enableRowNumbers
        enableRowOrdering
        enableRowSelection
        renderTopToolbarCustomActions={({ table }) => {
          const handleDelete = () => {
            setLoadSpinner(true);
            setModalResponse(false);
            table.getSelectedRowModel().flatRows.forEach((row) => {
              axiosApiInstance
                .delete(
                  API_INVESTMENT_TYPE_URL +
                    `/${investmentTypeUid}/functional-groups/${row.original.uid}`
                )
                .then(() => {
                  setReloadFunctionalGroups(true);
                  setIsError(false);
                  setMsg('The approver has been removed correctly!!');
                  setLoadSpinner(false);
                  setModalResponse(true);
                })
                .catch((error) => {
                  const errorMessage = error.response ? error.response.data.Message : error.message;
                  setIsError(true);
                  setMsg(errorMessage);
                  setLoadSpinner(false);
                  setModalResponse(true);
                });
            });
          };

          return (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              {loadSpinner ? (
                <SpinnerButtons
                  text="Delete group of approver..."
                  textButton="Delete"
                ></SpinnerButtons>
              ) : (
                <Button
                  color="error"
                  disabled={table.getSelectedRowModel().flatRows.length === 0}
                  onClick={handleDelete}
                  variant="contained"
                  className="botonTable"
                >
                  Delete
                </Button>
              )}
              {loadSpinnerMove ? (
                <SpinnerButtons
                  text="Groups of approvers are moving..."
                  textButton="Save changes"
                ></SpinnerButtons>
              ) : (
                <Button
                  color="error"
                  onClick={handleSaveChanges}
                  variant="contained"
                  className="botonTable"
                >
                  Save changes
                </Button>
              )}
            </div>
          );
        }}
        enableSorting={false}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(hoveredRow.index, 0, data.splice(draggingRow.index, 1)[0]);
              setDataMove([...data]);
            }
          },
        })}
      />
    </>
  );
};
