import { useMsal } from '@azure/msal-react';
import { useState, useEffect, useCallback } from 'react';
import { useCatalogContext } from '../CatalogContext';
import axiosApiInstance from '../config/axiosApiInstance';
import { getCatalogValue } from '../services/CatalogService';
import { isArrayEmpty } from '../utils/CheckUtils';

export const useProjectsByUser = (url) => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState('');
  const { catalogs } = useCatalogContext();
  const [isLoading, setIsloading] = useState(isArrayEmpty(projects));
  const { instance } = useMsal();

  const addDescriptionProjects = useCallback(
    (projects) => {
      let projectsWithDescription = [];
      projects.forEach((project) => {
        const subtypeDescription = getCatalogValue(catalogs, 'subtype', project.subtype);
        const headquartersDescription = getCatalogValue(
          catalogs,
          'headquarters',
          project.headquartersId
        );
        projectsWithDescription.push({ ...project, subtypeDescription, headquartersDescription });
      });

      return projectsWithDescription;
    },
    [catalogs]
  );

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const user = await instance.getActiveAccount();
        const { data } = await axiosApiInstance.get(url + `/${user.username}`);
        const responseDetail = data;
        const projects = addDescriptionProjects(responseDetail.data);
        setProjects(projects);
        setIsloading(false);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setError('Error Fetching Projects ->' + errorMessage);
        setIsloading(false);
      }
    };

    if (!isArrayEmpty(catalogs)) {
      fetchProjectsData();
    }
  }, [catalogs, addDescriptionProjects, instance, url]);

  return { projects, isLoading, error };
};
