export function isArrayEmpty(arr) {
  return !arr || typeof arr === 'undefined' || !Array.isArray(arr) || arr.length === 0;
}

export function isObjectEmpty(obj) {
  return (
    !obj ||
    typeof obj === 'undefined' ||
    (Object.keys(obj).length === 0 && obj.constructor === Object)
  );
}
export function isVariableEmpty(variable) {
  return !variable || typeof variable === 'undefined';
}
