import { AddComment } from './AddComment';
import { CommentsList } from './CommentsList';
import { useProjectComments } from '../../../hooks/useProjectComments';

export function Comments({ project }) {
  const { projectComments } = useProjectComments(project.uid);
  return (
    <>
      <div>
        <CommentsList comments={projectComments} />
        <br />
        <AddComment comments={projectComments} project={project} />
      </div>
    </>
  );
}
