import { AuthenticatedTemplate } from '@azure/msal-react';
import { useCanUserViewComponent } from '../../hooks/useCanUserViewComponent';

export function AuthenticatedComponent(props) {
  const { canUserViewComponent, isCheking } = useCanUserViewComponent(props);

  if (isCheking) return <></>;
  return (
    <AuthenticatedTemplate>{canUserViewComponent ? props.children : <></>}</AuthenticatedTemplate>
  );
}
