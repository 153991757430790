const standardPagination = {
  pagination: {
    pageIndex: 0,
    pageSize: 3,
  },
};

const pagination = {
  pagination: {
    pageIndex: 0,
    pageSize: 15,
  },
};

const initialState = {
  density: 'compact',
  showProgressBars: false,
  showSkeletons: true,
};

export const StandardInitialState = {
  ...initialState,
  ...standardPagination,
};

export const InitialState = {
  ...initialState,
  ...pagination,
};
