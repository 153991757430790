import { createContext, useContext, useState } from 'react';

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [showAddComment, setShowAddComment] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [reloadComments, setReloadComments] = useState(false);
  const [reloadProject, setReloadProject] = useState(false);
  const [reloadDocuments, setReloadDocuments] = useState(false);
  const [show, setShow] = useState(false);
  const [projectUid, setProjectUid] = useState('');
  const [isNewProject, setIsNewProject] = useState(false);
  const [showAddDocuments, setShowAddDocuments] = useState(false);
  const [documentsAttachedNewProject, setDocumentsAttachedNewProject] = useState([]);
  const [key, setKey] = useState('comments');
  const [loadSpinnerDuplicate, setLoadSpinnerDuplicate] = useState(false);
  const [loadSpinnerSave, setLoadSpinnerSave] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [modalResponseDocument, setModalResponseDocument] = useState(false);
  const [isErrorDocument, setIsErrorDocument] = useState(false);
  const [msgDocument, setMsgDocument] = useState('');
  const [isWarningDocument, setIsWarningDocument] = useState(false);

  return (
    <ProjectContext.Provider
      value={{
        showAddComment,
        setShowAddComment,
        editProject,
        setEditProject,
        reloadComments,
        setReloadComments,
        reloadProject,
        setReloadProject,
        reloadDocuments,
        setReloadDocuments,
        show,
        setShow,
        projectUid,
        setProjectUid,
        showAddDocuments,
        setShowAddDocuments,
        isNewProject,
        setIsNewProject,
        documentsAttachedNewProject,
        setDocumentsAttachedNewProject,
        key,
        setKey,
        loadSpinnerDuplicate,
        setLoadSpinnerDuplicate,
        loadSpinnerSave,
        setLoadSpinnerSave,
        duplicate,
        setDuplicate,
        modalResponseDocument,
        setModalResponseDocument,
        isErrorDocument,
        setIsErrorDocument,
        msgDocument,
        setMsgDocument,
        isWarningDocument,
        setIsWarningDocument,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);

  if (context === undefined)
    throw new Error('ProjectContext must be used with ProjectContext.Provider');

  return context;
};
