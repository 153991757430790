import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

export function SpinnerButtons({ text, textButton }) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  let textDisplayed = 'Loading...';
  if (text !== null && text !== '') {
    textDisplayed = text;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="text-center">
          <>
            <p>{textDisplayed}</p>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </>
        </Modal.Body>
      </Modal>
      {textButton !== null && textButton !== '' && (
        <>
          <Button className="botonComment">{textButton}</Button>
          &nbsp;
        </>
      )}
    </>
  );
}
