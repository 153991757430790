import { Badge, Col, Row } from 'react-bootstrap';
import { TabMenu } from '../components/TabMenu';
import ProjectDetail from '../components/ProjectDetail';
import { Buttons } from './Buttons';
import EditProject from './EditProject';
import { useEffect, useState } from 'react';
import { SkeletonProjectLayout } from '../skeleton/SkeletonProjectLayout';
import { Navigate, useParams } from 'react-router-dom';
import { useProjectDetail } from '../../../hooks/useProjectDetail';
import { useProjectContext } from '../context/ProjectContext';

export function ProjectLayout() {
  const { code } = useParams();
  const { project } = useProjectDetail(code);
  const { editProject, setProjectUid, setIsNewProject, catalogs } = useProjectContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setIsNewProject(false);
    if (project === null) {
      return <Navigate to="/" replace />;
    }
    if (typeof project.name !== 'undefined') {
      setLoading(false);
      setProjectUid(project.uid);
    }
  }, [project, setProjectUid, setIsNewProject, catalogs]);

  return (
    <>
      {loading ? (
        <SkeletonProjectLayout />
      ) : (
        <>
          <br />
          <Col>
            <Row>
              <Col className="col-md-8">
                <h2 className="colorDarkGrey">{project.name}</h2>
                <small>({project.code})</small>
              </Col>
              <Col className="indent-1">
                <Buttons project={project} />
              </Col>
            </Row>
          </Col>

          <h6>
            <Badge className="dacsa-yellow">{project.type}</Badge> &nbsp;
            <Badge className="dacsa-yellow">{project.subtypeDescription}</Badge>
          </h6>
          {!editProject ? <ProjectDetail project={project} /> : <EditProject project={project} />}
          <Col className="col-md-8">
            <hr />
            <TabMenu project={project} />
          </Col>
        </>
      )}
    </>
  );
}
