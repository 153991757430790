import { useState, useRef, useEffect } from 'react';
import { Form, Col, Row, Container } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import CampoFecha from './CampoFecha';
import { useCatalogContext } from '../../../CatalogContext';
import { getCatalog } from '../../../services/CatalogService';
import { API_PROJECT_URL } from '../../../config/ApiURLs';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';
import { useProjectContext } from '../context/ProjectContext';
import { SelectProjectManager } from './SelectProjectManager';
import { ModalResponse } from '../../../components/ModalResponse';
import { FormCatalogSelect } from '../../../components/FormCatalogSelect';

export function DuplicateProject({ project }) {
  const { catalogs } = useCatalogContext();
  const sizeLabel = 3;
  const sizeCol = 9;
  const tamanyoSelects = 6;

  const catalogType = getCatalog(catalogs, 'type');
  const catalogSubtype = getCatalog(catalogs, 'subtype');
  const catalogHeadquarters = getCatalog(catalogs, 'headquarters');
  const catalogArea = getCatalog(catalogs, 'area');
  const catalogCategory = getCatalog(catalogs, 'category');
  const catalogBadge = getCatalog(catalogs, 'badge');
  const catalogSubheadquarters = getCatalog(catalogs, 'subheadquarters');
  const catalogDepartment = getCatalog(catalogs, 'department');

  const [type, setType] = useState('FIRST');
  const [headquarters, setHeadquarters] = useState('FIRST');
  const [department, setDepartment] = useState('FIRST');
  const [subtypeList, setSubtypeList] = useState([]);
  const [haveSubheadquarters, setHaveSubheadquarters] = useState(false);
  const [subheadquartersList, setSubheadquartersList] = useState([]);

  const selectInputRefSubtype = useRef();
  const selectInputRefSubheadquarters = useRef();
  const selectInputRefHeadquarters = useRef();
  const selectInputRefDepartment = useRef();
  const { TaskSuperUser } = appRoles;
  const { setShow, setReloadProject, setLoadSpinnerDuplicate } = useProjectContext();

  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [headquartersPl, setHeadquartersPl] = useState([]);
  const [isWarning, setIsWarning] = useState(false);
  const [projectCode, setProjectCode] = useState('');
  const showTypeofproject = project.type === 'SPECIALS';

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: project.name,
      type: project.type,
      subtype: project.subtype,
      headquarters: project.headquartersId,
      subheadquarters: project.subheadquartersId,
      area: project.areaId,
      category: project.categoryId,
      budget: project.budget,
      badge: project.badgeId,
      startDate: project.startDate,
      expectedEndDate: project.expectedEndDate,
      summary: project.summary,
      projectManager: project.projectManager,
      department: project.departmentId,
      visibility: project.visibility,
      typeofproject: project.typeOfProjectId,
    },
  });

  const onClear = (ref) => {
    ref.current.clearValue();
  };

  const handleTypeChange = (obj) => {
    onClear(selectInputRefSubtype);
    onClear(selectInputRefHeadquarters);

    if (project.departmentId !== null && department !== 'FIRST') {
      onClear(selectInputRefDepartment);
    }
    if (obj === null) {
      setSubtypeList([]);
      setType('');
    } else {
      let subtypes = [];
      catalogSubtype.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === obj.code) {
          subtypes.push(valueCatalog);
        }
        return subtypes;
      });

      setSubtypeList(subtypes);
      setType(obj.code);
    }

    if (obj !== null && obj.code === 'PLANT') {
      let headquartersPlant = [];
      catalogHeadquarters.catalogValues.map((valueCatalog) => {
        if (valueCatalog.code !== 'ALL') {
          headquartersPlant.push(valueCatalog);
        }
        return headquartersPlant;
      });
      setHeadquartersPl(headquartersPlant);
    }
  };

  const handleSubtypeChanged = (obj) => {
    if (obj === null) {
      setSubheadquartersList([]);
      setHaveSubheadquarters(false);
      setHeadquarters('');
    } else {
      let catalogoSubheadquarters = [];
      catalogSubheadquarters.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === obj.code) {
          catalogoSubheadquarters.push(valueCatalog);
        }
        return catalogoSubheadquarters;
      });

      setSubheadquartersList(catalogoSubheadquarters);

      if (catalogoSubheadquarters.length > 0) {
        setSubheadquartersList(catalogoSubheadquarters);
        setHaveSubheadquarters(true);
        setHeadquarters(obj.value);
      } else {
        setSubheadquartersList([]);
        setHaveSubheadquarters(false);
      }
    }
  };

  const handleDepartmentChange = (obj) => {
    setDepartment(obj.code);
  };

  useEffect(() => {
    if (project.type != null && project.subtype != null && type === 'FIRST') {
      let subtypes = [];
      catalogSubtype.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === project.type) {
          subtypes.push(valueCatalog);
        }
        return subtypes;
      });

      setSubtypeList(subtypes);
    }
    if (
      (project.headquartersId != null &&
        project.subheadquartersId != null &&
        headquarters === 'FIRST') ||
      headquarters === ''
    ) {
      let sede = headquarters;
      if (sede === 'FIRST') {
        sede = project.subtype;
      }
      let catalogoSubheadquarters = [];
      catalogSubheadquarters.catalogValues.forEach((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === sede) {
          catalogoSubheadquarters.push(valueCatalog);
        }
        return catalogoSubheadquarters;
      });

      if (catalogoSubheadquarters.length > 0) {
        setSubheadquartersList(catalogoSubheadquarters);
        setHaveSubheadquarters(true);
      } else {
        setSubheadquartersList([]);
        setHaveSubheadquarters(false);
      }
    }
    if ((project.type === 'PLANT' && type === 'FIRST') || type === 'PLANT') {
      let headquartersPlant = [];
      catalogHeadquarters.catalogValues.map((valueCatalog) => {
        if (valueCatalog.code !== 'ALL') {
          headquartersPlant.push(valueCatalog);
        }
        return headquartersPlant;
      });
      setHeadquartersPl(headquartersPlant);
    }
  }, [
    project,
    setSubtypeList,
    type,
    catalogSubtype,
    setSubheadquartersList,
    setHaveSubheadquarters,
    haveSubheadquarters,
    catalogHeadquarters,
    catalogSubheadquarters,
    headquarters,
    projectCode,
  ]);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    setLoadSpinnerDuplicate(true);
    let formData = new FormData(document.querySelector('#hook-form-duplicate'));
    let startDateFormat = data.startDate;
    let endDateFormat = data.expectedEndDate;
    if (data.startDate !== '' && data.startDate.toDateString !== undefined)
      startDateFormat = data.startDate.toDateString();
    if (data.expectedEndDate !== '' && data.expectedEndDate.toDateString !== undefined)
      endDateFormat = data.expectedEndDate.toDateString();
    formData.set('startDate', startDateFormat);
    formData.set('expectedEndDate', endDateFormat);
    formData.append('isDuplicated', true);
    formData.append('originProjectCode', project.code);
    formData.set('projectManager', data.projectManager);

    axiosApiInstance
      .post(API_PROJECT_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        handleEliminate(res.data);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setLoadSpinnerDuplicate(false);
        if (error.response && error.response.data.TypeResponse === 1) {
          setProjectCode(errorMessage.split('-')[0]);
          setIsError(false);
          setIsWarning(true);
          setMsg(errorMessage);
        } else {
          setIsError(true);
          setIsWarning(false);
          setMsg(errorMessage);
        }
        setModalResponse(true);
      });
  };

  const handleModalCallbackButton = () => {
    if (projectCode !== '') {
      navigate(`/project/${projectCode}`);
    }
    setShow(false);
    setReloadProject(true);
  };

  const handleModalCallbackClose = () => {
    setShow(false);
    setReloadProject(true);
  };

  const handleEliminate = (code) => {
    setLoadSpinnerDuplicate(false);
    if (project.status === 'PENDING') {
      axiosApiInstance
        .post(API_PROJECT_URL + `/${project.uid}/manage-status?action=eliminate`)
        .then(() => {
          navigate('/project/' + code.data);
          setReloadProject(true);
          setLoadSpinnerDuplicate(false);
          setMsg('The project has been duplicated and removed correctly!!');
          setIsError(false);
          setIsWarning(false);
          setModalResponse(true);
        })
        .catch((error) => {
          const errorMessage = error.response ? error.response.data.Message : error.message;
          setLoadSpinnerDuplicate(false);
          if (error.response && error.response.data.TypeResponse === 1) {
            setProjectCode(errorMessage.split('-')[0]);
            setIsError(false);
            setIsWarning(true);
            setMsg(errorMessage);
          } else {
            setIsError(true);
            setIsWarning(false);
            setMsg(errorMessage);
          }
          setModalResponse(true);
        });
    } else {
      navigate('/project/' + code.data);
      setReloadProject(true);
      setLoadSpinnerDuplicate(false);
      setMsg('The project has been duplicated correctly!!');
      setIsError(false);
      setIsWarning(false);
      setModalResponse(true);
    }
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <Container>
        <Form id="hook-form-duplicate" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col className="col-md-8">
              <strong>Details</strong>
            </Col>
          </Row>
          <Row>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column xl={sizeLabel}>
                Type:
              </Form.Label>
              <Col xl={tamanyoSelects}>
                <Controller
                  control={control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={catalogType.catalogValues}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value ? catalogType.catalogValues.find((x) => x.code === value) : value
                      }
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                        handleTypeChange(option);
                      }}
                      ref={ref}
                    />
                  )}
                />
                {errors.type && (
                  <Form.Text className="text-danger">The type field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="subtype">
              <Form.Label column xl={sizeLabel}>
                Subtype:
              </Form.Label>
              <Col xl={tamanyoSelects}>
                <Controller
                  control={control}
                  name="subtype"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={subtypeList}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={value ? subtypeList.find((x) => x.code === value) : value}
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                        handleSubtypeChanged(option);
                      }}
                      ref={selectInputRefSubtype}
                    />
                  )}
                />
                {errors.subtype && (
                  <Form.Text className="text-danger">The subtype field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column xl={sizeLabel}>
                Project Name:
              </Form.Label>
              <Col xl={sizeCol}>
                <Form.Control
                  type="text"
                  placeholder="Enter project name"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <Form.Text className="text-danger">The project name field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column xl={sizeLabel}>
                Summary:
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Project summary"
                className="indent-1 textareaCreate"
                cols="40"
                rows="5"
                {...register('summary', { required: true })}
              />
              {errors.summary && (
                <Form.Text className="text-danger">The summary field is required</Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="projectManager">
              <Form.Label column xl={sizeLabel}>
                Project manager:
              </Form.Label>
              <Col xl={tamanyoSelects}>
                <Controller
                  control={control}
                  name="projectManager"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <SelectProjectManager value={value} onChange={onChange} name={name} />
                  )}
                />
                {errors.projectManager && (
                  <Form.Text className="text-danger">
                    The project manager field is required
                  </Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="headquarters">
              <Form.Label column xl={sizeLabel}>
                Headquarters:
              </Form.Label>
              <Col xl={tamanyoSelects}>
                <Controller
                  control={control}
                  name="headquarters"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={
                        headquartersPl.length > 0 && (type === 'FIRST' || type === 'PLANT')
                          ? headquartersPl
                          : catalogHeadquarters.catalogValues
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value
                          ? headquartersPl.length > 0
                            ? headquartersPl.find((x) => x.code === value)
                            : catalogHeadquarters.catalogValues.find((x) => x.code === value)
                          : value
                      }
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                      }}
                      ref={selectInputRefHeadquarters}
                    />
                  )}
                />
                {errors.headquarters && (
                  <Form.Text className="text-danger">The headquarters field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            {((project.headquartersDescription === 'VALENCIA' && headquarters === 'FIRST') ||
              haveSubheadquarters) && (
              <Form.Group as={Row} className="mb-3" controlId="subheadquarters">
                <Form.Label column xl={sizeLabel}>
                  Sub-headquarters:
                </Form.Label>
                <Col xl={tamanyoSelects}>
                  <Controller
                    control={control}
                    name="subheadquarters"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name}
                        placeholder="Select value"
                        isClearable
                        options={subheadquartersList}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.code}
                        value={value ? subheadquartersList.find((x) => x.code === value) : value}
                        onChange={(option) => onChange(option ? option.code : option)}
                        ref={selectInputRefSubheadquarters}
                      />
                    )}
                  />
                  {errors.subheadquarters && (
                    <Form.Text className="text-danger">
                      The sub-headquarters field is required
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            )}
            {((project.type === 'CORPORATE' && type === 'FIRST') || type === 'CORPORATE') && (
              <Form.Group as={Row} className="mb-3" controlId="v">
                <Form.Label column xl={sizeLabel}>
                  Department:
                </Form.Label>
                <Col xl={tamanyoSelects}>
                  <Controller
                    control={control}
                    name="department"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name}
                        placeholder="Select value"
                        isClearable
                        options={catalogDepartment.catalogValues}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.code}
                        value={
                          value
                            ? catalogDepartment.catalogValues.find((x) => x.code === value)
                            : value
                        }
                        onChange={(option) => {
                          onChange(option ? option.code : option);
                          handleDepartmentChange(option);
                        }}
                        ref={selectInputRefDepartment}
                      />
                    )}
                  />
                  {errors.department && (
                    <Form.Text className="text-danger">The department field is required</Form.Text>
                  )}
                </Col>
              </Form.Group>
            )}
            {showTypeofproject && (
              <FormCatalogSelect
                as={Row}
                catalogType={'typeofproject'}
                control={control}
                errors={errors}
                rules={{ required: true }}
                className="mb-3"
                sizeCol={tamanyoSelects}
                sizeLabel={sizeLabel}
              />
            )}
            <Form.Group as={Row} className="mb-3" controlId="area">
              <Form.Label column xl={sizeLabel}>
                Area:
              </Form.Label>
              <Col xl={tamanyoSelects}>
                <Controller
                  control={control}
                  name="area"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={catalogArea.catalogValues}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value ? catalogArea.catalogValues.find((x) => x.code === value) : value
                      }
                      onChange={(option) => onChange(option ? option.code : option)}
                      ref={ref}
                    />
                  )}
                />
                {errors.area && (
                  <Form.Text className="text-danger">The area field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="category">
              <Form.Label column xl={sizeLabel}>
                Category:
              </Form.Label>
              <Col xl={tamanyoSelects}>
                <Controller
                  control={control}
                  name="category"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={catalogCategory.catalogValues}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value ? catalogCategory.catalogValues.find((x) => x.code === value) : value
                      }
                      onChange={(option) => onChange(option ? option.code : option)}
                      ref={ref}
                    />
                  )}
                />
                {errors.category && (
                  <Form.Text className="text-danger">The category field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="budget">
              <Form.Label column xl={sizeLabel}>
                Budget:
              </Form.Label>
              <Col xl="3">
                <Form.Control
                  type="number"
                  placeholder="Enter budget"
                  {...register('budget', { required: true })}
                />
                {errors.budget && (
                  <Form.Text className="text-danger">The budget field is required</Form.Text>
                )}
              </Col>
              <Col xl="3">
                <Controller
                  control={control}
                  name="badge"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select"
                      isClearable
                      options={catalogBadge.catalogValues}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value ? catalogBadge.catalogValues.find((x) => x.code === value) : value
                      }
                      onChange={(option) => onChange(option ? option.code : option)}
                      ref={ref}
                    />
                  )}
                />
                {errors.badge && (
                  <Form.Text className="text-danger">The badge field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="startDate">
              <Form.Label column xl={sizeLabel}>
                Start date:
              </Form.Label>
              <Col sm={tamanyoSelects}>
                <CampoFecha name="startDate" control={control} />
                {errors.startDate && (
                  <Form.Text className="text-danger">The start date field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="expectedEndDate">
              <Form.Label column xl={sizeLabel}>
                Expected end date:
              </Form.Label>
              <Col sm={tamanyoSelects}>
                <CampoFecha name="expectedEndDate" control={control} />
                {errors.expectedEndDate && (
                  <Form.Text className="text-danger">
                    The expected end date field is required
                  </Form.Text>
                )}
              </Col>
            </Form.Group>
          </Row>
          <AuthenticatedComponent roles={[TaskSuperUser]}>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3" controlId="selfApproval">
                  <Form.Label column xl={sizeLabel}>
                    Self approved:
                  </Form.Label>
                  <Col xl="2" className="checkIndent-1">
                    <Form.Check {...register('selfApproval')} type="checkbox" />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </AuthenticatedComponent>
        </Form>
      </Container>
    </>
  );
}
