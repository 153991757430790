import { useState, useEffect } from 'react';

import axiosApiInstance from '../config/axiosApiInstance';
import { isObjectEmpty } from '../utils/CheckUtils';
import { useInvestmentContext } from '../pages/Investment/components/context/InvestmentContext';

export const useCatalogFunctionalGroupsByType = (url) => {
  const [catalogFunctionalGroupsByType, setCatalogFunctionalGroupsByType] = useState({});

  const isCatalogFunctionalGroupsByTypeLoading = isObjectEmpty(catalogFunctionalGroupsByType);
  const { reloadFunctionalGroups, setReloadFunctionalGroups } = useInvestmentContext();

  useEffect(() => {
    const fetchcatalogFunctionalGroupsByTypeData = async () => {
      try {
        const { data } = await axiosApiInstance.get(url);
        const responseDetail = data;
        setCatalogFunctionalGroupsByType(responseDetail.data);
        setReloadFunctionalGroups(false);
      } catch (error) {
        setReloadFunctionalGroups(false);
        const errorMessage = error.response ? error.response.data.Message : error.message;
        console.log('Error Feching catalogFunctionalGroupsByType -> ' + errorMessage);
      }
    };

    if (isCatalogFunctionalGroupsByTypeLoading || reloadFunctionalGroups) {
      fetchcatalogFunctionalGroupsByTypeData();
    }
  }, [
    isCatalogFunctionalGroupsByTypeLoading,
    setCatalogFunctionalGroupsByType,
    url,
    reloadFunctionalGroups,
    setReloadFunctionalGroups,
  ]);

  return { catalogFunctionalGroupsByType };
};
