import { Col, Form, Row } from 'react-bootstrap';
import { Skeleton } from '@mui/material';
import SkeletonProjectDetail from '../skeleton/SkeletonProjectDetail';
import { SkeletonTabMenu } from './SkeletonTabMenu';

export function SkeletonProjectLayout() {
  return (
    <>
      <br />
      <Col>
        <Row>
          <Col className="col-md-8">
            <h2 className="colorDarkGrey">
              <Skeleton width={400} height={50} />
            </h2>
            <small>
              <Skeleton width={50} />
            </small>
          </Col>
          <Col className="indent-2">
            <Row>
              <Skeleton width={60} height={50} />
              &nbsp;
              <Skeleton width={60} height={50} />
              &nbsp;
              <Skeleton width={60} height={50} />
              &nbsp;
              <Skeleton width={60} height={50} />
            </Row>
          </Col>
        </Row>
      </Col>

      <h6>
        <Form.Label>
          <Skeleton width={160} sx={{ bgcolor: '#ffae00' }} />
        </Form.Label>
        &nbsp;
        <Form.Label>
          <Skeleton width={160} sx={{ bgcolor: '#ffae00' }} />
        </Form.Label>
      </h6>
      <SkeletonProjectDetail />
      <SkeletonTabMenu />
    </>
  );
}
