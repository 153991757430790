import React from 'react';
import { Row } from 'react-bootstrap';
import { useProjectDocuments } from '../../../hooks/useProjectDocuments';
import { isArrayEmpty } from '../../../utils/CheckUtils';
import { useProjectContext } from '../context/ProjectContext';
import { DocumentItem } from './DocumentItem';
import { DocumentNewItem } from './DocumentNewItem';

export function DocumentList() {
  const { projectUid, isNewProject, documentsAttachedNewProject } = useProjectContext();
  const { projectDocuments } = useProjectDocuments(projectUid);

  if (isNewProject && !isArrayEmpty(documentsAttachedNewProject)) {
    return (
      <>
        <br />
        <Row id="align-row">
          {documentsAttachedNewProject.map((document) => (
            <DocumentNewItem key={document.name} documento={document} />
          ))}
        </Row>
      </>
    );
  }

  if (!isArrayEmpty(projectDocuments)) {
    return (
      <>
        <br />
        <Row id="align-row">
          {projectDocuments.map((document) => (
            <DocumentItem key={document.uid} documento={document} />
          ))}
        </Row>
      </>
    );
  }

  return (
    <>
      <br />
      <Row className="indent-2">
        <p>There are no documents on this project.</p>
      </Row>
    </>
  );
}
