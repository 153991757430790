import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Approvers } from './Approvers';
import { Viewers } from './Viewers';

export const TabMenuInvestment = ({ investmentType }) => {
  return (
    <>
      <Tabs
        defaultActiveKey="approvers"
        transition={false}
        id="noanim-tab-example"
        className="mb-3 navColorGrey alignEnd"
      >
        <Tab eventKey="approvers" title="Approvers">
          <Approvers investmentType={investmentType} />
        </Tab>
        <Tab eventKey="viewers" title="Viewers">
          <Viewers investmentType={investmentType} />
        </Tab>
      </Tabs>
    </>
  );
};
