import { useState, useEffect } from 'react';
import { API_ATTACHMENTS_PROJECT } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { useProjectContext } from '../pages/Project/context/ProjectContext';
import { isArrayEmpty, isVariableEmpty } from '../utils/CheckUtils';

export const useProjectDocuments = (uidProject) => {
  const [projectDocuments, setProjectDocuments] = useState([]);
  const { reloadDocuments, setReloadDocuments } = useProjectContext();

  const isProjectDocumentsLoading = isArrayEmpty(projectDocuments);

  useEffect(() => {
    const fetchProjectDocumentsData = async () => {
      try {
        const { data } = await axiosApiInstance.get(API_ATTACHMENTS_PROJECT + `/${uidProject}`);
        const responseDetail = data;
        setProjectDocuments(responseDetail.data);
        setReloadDocuments(false);
      } catch (error) {
        setProjectDocuments([]);
        setReloadDocuments(false);
      }
    };

    if ((isProjectDocumentsLoading || reloadDocuments) && !isVariableEmpty(uidProject)) {
      fetchProjectDocumentsData();
    }
  }, [isProjectDocumentsLoading, uidProject, reloadDocuments, setReloadDocuments]);

  return { projectDocuments };
};
