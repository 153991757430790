import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import './DocumentItem.css';
import { useProjectContext } from '../context/ProjectContext';
import { formatFileSize, getExtension } from '../../../utils/FileUtils';
import { isArrayEmpty } from '../../../utils/CheckUtils';

export function DocumentNewItem({ documento }) {
  const extension = getExtension(documento.name);
  const { documentsAttachedNewProject, setDocumentsAttachedNewProject } = useProjectContext();

  const handleDownload = async (name) => {
    const file = documentsAttachedNewProject.find((file) => file.name === name);
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    if (typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(file, name);
    } else {
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleDelete = async (documento) => {
    if (!isArrayEmpty(documentsAttachedNewProject)) {
      setDocumentsAttachedNewProject(
        documentsAttachedNewProject.filter((file) => {
          return file.name !== documento.name;
        })
      );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <div className="icon">
                <FileIcon extension={extension} {...defaultStyles[extension]} />
              </div>
            </Col>
            <Col>
              <button
                className="botonExcel btn btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload(documento.name);
                }}
              >
                {documento.name}
              </button>
            </Col>
          </Row>
        </Col>
        <Col>{formatFileSize(documento.size)}</Col>
        <Col>
          <IconButton onClick={() => handleDelete(documento)}>
            <Delete className="link" />
          </IconButton>
        </Col>
      </Row>
    </>
  );
}
