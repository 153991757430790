import { Spinner } from 'react-bootstrap';
import { LogoApp } from './LogoApp';

export function InitialLoading() {
  return (
    <LogoApp>
      <div>
        <Spinner animation="border" role="status" />
      </div>
    </LogoApp>
  );
}
