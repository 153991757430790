import { Container } from 'react-bootstrap';
import { NavMenu } from '../components/NavMenu';
import { Footer } from '../components/Footer';
import '../styles/Layout.css';
import { Outlet } from 'react-router-dom';
import { useCatalogs } from '../hooks/useCatalogs';
import { InitialLoading } from '../components/InitialLoading';
import { useIsAuthenticated } from '@azure/msal-react';

export function Layout() {
  const isAuthenticated = useIsAuthenticated();
  const isLoadingCatalogs = useCatalogs();

  if (isAuthenticated && isLoadingCatalogs) return <InitialLoading />;

  return (
    <>
      <NavMenu />
      <Container tag="main" fluid className="minHeight">
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}
