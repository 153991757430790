import { ProjectProvider } from './context/ProjectContext';
import { ProjectLayout } from './components/ProjectLayout';
import { NewProject } from './components/NewProject';

export function Project({ action }) {
  return (
    <ProjectProvider>
      {action === 'NewProject' ? <NewProject /> : <ProjectLayout />}
    </ProjectProvider>
  );
}
