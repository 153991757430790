export function getExtension(filename) {
  return filename.split('.').pop();
}

export function formatFileSize(bytes) {
  let unit = 1024;
  if (bytes < unit) {
    return bytes + ' B';
  }
  let exp = Math.floor(Math.log(bytes) / Math.log(unit));
  return (bytes / Math.pow(unit, exp)).toFixed(2) + ' ' + 'KMGTPE'[exp - 1] + 'B';
}
