import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { EditInvestmentType } from './EditInvestmentType';
import { TabMenuInvestment } from './TabMenuInvestment';
import { InvestmentTypeDetail } from './InvestmentTypeDetail';
import { SkeletonInvestmentTypeLayout } from './skeleton/SkeletonInvestmentTypeLayout';
import { useInvestmentTypeDetail } from '../../../hooks/useInvestmentTypeDetail';
import { useInvestmentContext } from './context/InvestmentContext';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';

export function InvestmentTypeLayout() {
  const { code } = useParams();
  const { investmentType } = useInvestmentTypeDetail(code);
  const {
    editInvestment,
    setEditInvestment,
    setInvestmentTypeUid,
    loadSpinnerInvestmentType,
    modalResponseEditInvestment,
    isErrorEditInvestment,
    msgEditInvestment,
    setLoadSpinnerInvestmentType,
    setModalResponseEditInvestment,
    setReloadInvestment,
  } = useInvestmentContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoadSpinnerInvestmentType(false);
    setModalResponseEditInvestment(false);
    if (typeof investmentType.name !== 'undefined') {
      setInvestmentTypeUid(investmentType.uid);
      setLoading(false);
    }
  }, [
    investmentType,
    setInvestmentTypeUid,
    setLoadSpinnerInvestmentType,
    setModalResponseEditInvestment,
    setReloadInvestment,
  ]);

  const clickEdit = () => {
    setEditInvestment(true);
  };

  const clickCancel = () => {
    setEditInvestment(false);
  };

  const handleModalCallbackButton = () => {
    setEditInvestment(false);
    setReloadInvestment(true);
  };

  const handleModalCallbackClose = () => {
    setEditInvestment(false);
    setReloadInvestment(true);
  };

  return (
    <>
      {modalResponseEditInvestment && (
        <ModalResponse
          showModal={modalResponseEditInvestment}
          isError={isErrorEditInvestment}
          message={msgEditInvestment}
          buttonAccept={!isErrorEditInvestment}
          callbackButton={handleModalCallbackButton}
          callbackClose={isErrorEditInvestment ? undefined : handleModalCallbackClose}
        />
      )}
      {loading ? (
        <SkeletonInvestmentTypeLayout />
      ) : (
        <Container>
          <br />
          <Col>
            <Row>
              <Col className="col-md-8">
                <h2 className="colorDarkGrey">Investment Type</h2>
              </Col>
              <Col className="alignEnd">
                {!editInvestment ? (
                  <>
                    <Button className="botonComment" onClick={clickEdit}>
                      Edit
                    </Button>
                    &nbsp;
                    <Nav.Link
                      className="botonCancel btn btn-primary"
                      as={NavLink}
                      to={'/investmenttype'}
                    >
                      Cancel
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    {loadSpinnerInvestmentType ? (
                      <SpinnerButtons
                        text="Save investment type..."
                        textButton="Save"
                      ></SpinnerButtons>
                    ) : (
                      <>
                        <button
                          className="botonComment btn btn-primary"
                          type="submit"
                          form="hook-form-edit-investment"
                        >
                          Save
                        </button>
                        &nbsp;
                      </>
                    )}
                    <Button className="botonComment" onClick={clickCancel}>
                      Cancel
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          {!editInvestment ? (
            <InvestmentTypeDetail investmentType={investmentType} />
          ) : (
            <EditInvestmentType investmentType={investmentType} />
          )}
          <hr />
          <TabMenuInvestment investmentType={investmentType} />
        </Container>
      )}
    </>
  );
}
