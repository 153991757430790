import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Home } from '../../pages/Home';
import { loginRequest } from '../../config/authConfig';
import { useCanUserViewComponent } from '../../hooks/useCanUserViewComponent';
import { InitialLoading } from '../InitialLoading';
import { Navigate } from 'react-router-dom';

export function AuthenticatedLoginRedirectComponent(props) {
  const { canUserViewComponent, isCheking } = useCanUserViewComponent(props);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={Home}
    >
      {isCheking ? (
        <InitialLoading />
      ) : canUserViewComponent ? (
        props.children
      ) : (
        <Navigate to="/unauthorized" replace />
      )}
    </MsalAuthenticationTemplate>
  );
}
