import { API_FUNCTIONALGROUP_APPROVERS_URL } from '../config/ApiURLs';
import { useCatalogFunctionalGroupsByType } from './useCatalogFunctionalGroupsByType';

export const useApprovers = () => {
  const { catalogFunctionalGroupsByType: approvers } = useCatalogFunctionalGroupsByType(
    API_FUNCTIONALGROUP_APPROVERS_URL
  );

  return { approvers };
};
