import React from 'react';
import { utcStringDateTimeToLocalDateTimeFormat } from '../../../utils/DateTimeUtils';

export function CommentsItem({ comment }) {
  return (
    <>
      <p>
        <small>
          <b>
            <i>{comment.createdBy}</i>
          </b>{' '}
          added a comment - <b>{utcStringDateTimeToLocalDateTimeFormat(comment.createdDate)}</b>
        </small>
      </p>
      <p>{comment.content}</p>
      <hr />
    </>
  );
}
