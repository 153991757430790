import { useState, useEffect, useCallback } from 'react';
import { useCatalogContext } from '../CatalogContext';
import { API_INVESTMENT_TYPE_URL } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { useInvestmentContext } from '../pages/Investment/components/context/InvestmentContext';
import { getCatalogValue } from '../services/CatalogService';
import { isObjectEmpty } from '../utils/CheckUtils';

export const useInvestmentTypeDetail = (codeInvestmentType) => {
  const [investmentType, setInvestmentType] = useState({});
  const { catalogs } = useCatalogContext();
  const { reloadInvestment, setReloadInvestment } = useInvestmentContext();

  const isInvestmentTypeLoading = isObjectEmpty(investmentType);

  const setInvestmentTypeCatalogDescription = useCallback(
    (investmentType) => {
      const subtypeDescription = getCatalogValue(catalogs, 'subtype', investmentType.subtype);
      return {
        ...investmentType,
        subtypeDescription,
      };
    },
    [catalogs]
  );

  useEffect(() => {
    const fetchsetInvestmentTypeDetailData = async () => {
      try {
        const { data } = await axiosApiInstance.get(
          API_INVESTMENT_TYPE_URL + `/code/${codeInvestmentType}`
        );
        const responseDetail = data;
        const investmentType = setInvestmentTypeCatalogDescription(responseDetail.data);
        setInvestmentType(investmentType);
        setReloadInvestment(false);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        console.log('Error Feching Investment Type detail -> ' + errorMessage);
        setReloadInvestment(false);
      }
    };

    if (isInvestmentTypeLoading || reloadInvestment) {
      fetchsetInvestmentTypeDetailData();
    }
  }, [
    isInvestmentTypeLoading,
    codeInvestmentType,
    setInvestmentTypeCatalogDescription,
    reloadInvestment,
    setReloadInvestment,
  ]);

  return { investmentType, isInvestmentTypeLoading };
};
