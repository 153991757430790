const API_PREFIX = 'api'; //Prefijo de la ubicación de la Api

// PROJECTS
export const API_PROJECT_URL = `${API_PREFIX}/projects`; //ruta a la api para proyectos
export const API_PROJECT_CREATED_BY_USER_URL = `${API_PROJECT_URL}/createdbyuser`; //ruta a la api para catalogos de la aplicación
export const API_PROJECT_PENDING_APPROVAL_BY_USER_URL = `${API_PROJECT_URL}/pendingbyuser`;
export const API_PROJECT_INTERESTED_FOR_USER_URL = `${API_PROJECT_URL}/interestedforuser`;

// CATALOGS
export const API_CATALOG_URL = `${API_PREFIX}/catalogs`;

// INVESTMENT TYPE
export const API_INVESTMENT_TYPE_URL = `${API_PREFIX}/investmenttypes`;

// ATTACHMENTS
export const API_ATTACHMENTS = `${API_PREFIX}/attachments`;
export const API_ATTACHMENTS_PROJECT = `${API_ATTACHMENTS}/project`;

// FUNCTIONAL GROUP
export const API_FUNCTIONALGROUP_URL = `${API_PREFIX}/functionalgroups`;
export const API_FUNCTIONALGROUP_VIEWERS_URL = `${API_FUNCTIONALGROUP_URL}/type/VIEWERS`;
export const API_FUNCTIONALGROUP_APPROVERS_URL = `${API_FUNCTIONALGROUP_URL}/type/APPROVERS`;
