import { useMsal } from '@azure/msal-react';
import { isObjectEmpty } from '../utils/CheckUtils';

export const useUserName = () => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  if (!isObjectEmpty(currentAccount)) return currentAccount.name;

  return '';
};
