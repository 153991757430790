import { isArrayEmpty } from '../../../utils/CheckUtils';
import { CommentsItem } from './CommentsItem';

export function CommentsList({ comments }) {
  if (!isArrayEmpty(comments)) {
    return (
      <>
        <div className="scrollComments" id="scrollComments">
          <ul>
            {comments.map((comment) => (
              <CommentsItem key={comment.id} comment={comment} />
            ))}
          </ul>
          <br />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="scrollComments" id="scrollComments">
        <ul>
          <p>There are no comments on this project.</p>
        </ul>
        <br />
      </div>
    </>
  );
}
