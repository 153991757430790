import { Form, Col, Row } from 'react-bootstrap';

export function InvestmentTypeDetail({ investmentType }) {
  const sizeLabel = 3;
  const sizeCol = 9;
  const requiresAttached = investmentType.requiresAttached ? 'checked' : '';

  return (
    <>
      <br />
      <Col xl={{ order: 'first' }} className="col-md-8">
        <Row>
          <Col>
            <strong>Details</strong>
          </Col>
        </Row>
        <Form.Group controlId="requestName">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            Name:
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            {investmentType.name}
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="type">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            Type:
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            {investmentType.type}
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="subtype">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            Subtype:
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            {investmentType.subtypeDescription}
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="minAmount">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            Min Amount:
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            {investmentType.minAmount}
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="maxAmount">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            Max Amount:
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            {investmentType.maxAmount}
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row} controlId="requiresAttached">
          <Form.Label column xl={sizeLabel}>
            Requires attachment:
          </Form.Label>
          <Col className="checkIndent-1">
            <Form.Check type="checkbox" checked={requiresAttached} readOnly />
          </Col>
        </Form.Group>
      </Col>
    </>
  );
}
