import { Dashboard } from './pages/Dashboard/Dashboard';
import { Home } from './pages/Home';
import { Project } from './pages/Project/Project';
import { InvestmentList } from './pages/Investment/components/InvestmentList';
import { NewInvestmentType } from './pages/Investment/components/NewInvestmentType';
import { DuplicateProject } from './pages/Project/components/DuplicateProject';
import { Investment } from './pages/Investment/components/Investment';
import { ManageStatus } from './pages/ManageStatus/ManageStatus';
import { ErrorPage } from './pages/ErrorPage';
import { UNAUTHORIZED_APP_USER } from './config/Messages';
import { AuthenticatedLoginRedirectComponent } from './components/authentication/AuthenticatedLoginRedirectComponent';

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: '/unauthorized',
    element: <ErrorPage message={UNAUTHORIZED_APP_USER} />,
  },
  {
    path: '/dashboard',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <Dashboard />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/project/:code',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <Project />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/newproject',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <Project action="NewProject" />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/investmenttype',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <InvestmentList />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/newinvestmenttype',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <NewInvestmentType />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/investmenttype/:code',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <Investment />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/createduplicateproject/:code',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <DuplicateProject />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
  {
    path: '/remote-manage-status/:projectUid',
    element: (
      <AuthenticatedLoginRedirectComponent>
        <ManageStatus />
      </AuthenticatedLoginRedirectComponent>
    ),
  },
];

export default AppRoutes;
