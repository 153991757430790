import { Col, Row, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';
import { InvestmentDashboard } from './InvestmentDashboard';

export function InvestmentList() {
  const { TaskSuperUser, TaskAdmin, TaskUser } = appRoles;

  return (
    <div>
      <CabeceraInvestment />
      <AuthenticatedComponent roles={[TaskSuperUser, TaskAdmin]} exclude={[TaskUser]}>
        <InvestmentDashboard />
      </AuthenticatedComponent>
    </div>
  );
}

function CabeceraInvestment() {
  return (
    <>
      <br />
      <Col>
        <Row>
          <Col className="col-md-8">
            <h2 id="tableLabel" className="colorDarkGrey">
              Investment Type
            </h2>
          </Col>
          <Col className="indent-1">
            <Nav.Link
              className="botonNewProject btn btn-primary"
              as={NavLink}
              to={'/newinvestmenttype'}
            >
              New Investment Type
            </Nav.Link>
          </Col>
        </Row>
        <br />
      </Col>
    </>
  );
}
