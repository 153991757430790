import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { appRoles } from '../config/authConfig';

export const useIsUserAuthorized = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const currentAccount = instance.getActiveAccount();

  if (!isAuthenticated) return false;

  return currentAccount.idTokenClaims['roles']?.some((role) => appRoles.AllRoles.includes(role));
};
