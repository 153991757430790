import {
  BrowserAuthError,
  BrowserAuthErrorMessage,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { msalInstance } from '../..';
import { loginRequest, usersSearch } from '../../config/authConfig';
import { Client } from '@microsoft/microsoft-graph-client';

export class AuthorizeService {
  static get instance() {
    return authService;
  }

  async getAccessToken() {
    return msalInstance
      .acquireTokenSilent(loginRequest)
      .then((authResult) => {
        // Do something with the tokenResponse
        return authResult.accessToken;
      })
      .catch(async (error) => {
        if (
          error instanceof InteractionRequiredAuthError ||
          (error instanceof BrowserAuthError &&
            error.errorCode === BrowserAuthErrorMessage.noAccountError.code)
        ) {
          // fallback to interaction when silent call fails
          return msalInstance
            .acquireTokenRedirect(loginRequest)
            .then((authResult) => {
              return authResult.accessToken;
            })
            .catch((e) => {
              return msalInstance
                .acquireTokenPopup(loginRequest)
                .then((authResult) => {
                  return authResult.accessToken;
                })
                .catch((e) => {
                  console.error(e);
                });
            });
        }
        // handle other errors
        console.error(error);
      });
  }

  async getGraphClient() {
    const account = msalInstance.getAllAccounts()[0];
    try {
      if (!account) {
        console.log('There is no active accounts');
        return;
      }

      const authResult = await msalInstance.acquireTokenSilent({
        oboAssertion: await this.getAccessToken(),
        ...usersSearch,
      });

      const client = Client.init({
        authProvider: (done) => done(null, authResult.accessToken),
      });

      return client;
    } catch (e) {
      console.error(e);
    }
  }
}

const authService = new AuthorizeService();
export default authService;
