import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { API_PROJECT_URL } from '../../../config/ApiURLs';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { useProjectDetail } from '../../../hooks/useProjectDetail';
import { useProjectContext } from '../context/ProjectContext';
import { DuplicateProject } from './DuplicateProject';
import { isObjectEmpty } from '../../../utils/CheckUtils';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';

export function ModalRemove() {
  const {
    show,
    setShow,
    setReloadProject,
    loadSpinnerDuplicate,
    setLoadSpinnerDuplicate,
    duplicate,
    setDuplicate,
  } = useProjectContext();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setDuplicate(false);
    if (!isObjectEmpty(project) && project.status !== 'PENDING') {
      setDuplicate(true);
    }
  };
  const { code } = useParams();
  const { project } = useProjectDetail(code);
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [isWarning, setIsWarning] = useState(false);

  const clickRemove = () => {
    setLoadSpinnerDuplicate(true);
    if (project.status === 'PENDING') {
      axiosApiInstance
        .post(API_PROJECT_URL + `/${project.uid}/manage-status?action=eliminate`)
        .then(() => {
          setReloadProject(true);
          setShow(false);
          setLoadSpinnerDuplicate(false);
          setMsg('The project has been successfully removed!!');
          setIsError(false);
          setIsWarning(false);
          setModalResponse(true);
        })
        .catch((error) => {
          const errorMessage = error.response ? error.response.data.Message : error.message;
          setShow(false);
          setLoadSpinnerDuplicate(false);
          if (error.response && error.response.data.TypeResponse === 1) {
            setIsError(false);
            setIsWarning(true);
            setMsg(errorMessage);
          } else {
            setIsError(true);
            setIsWarning(false);
            setMsg(errorMessage);
          }
          setModalResponse(true);
        });
    }
  };

  const clickDuplicate = () => {
    setDuplicate(true);
  };

  const handleModalCallbackButton = () => {
    setReloadProject(true);
  };

  const handleModalCallbackClose = () => {
    setReloadProject(true);
  };

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <Button className="botonComment" onClick={handleShow}>
        {project.status === 'PENDING' ? 'Remove' : 'Duplicate'}
      </Button>
      &nbsp;
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
      >
        <Modal.Header closeButton>
          {!duplicate ? (
            <Modal.Title>Remove project</Modal.Title>
          ) : (
            <Modal.Title>Duplicate project</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {!duplicate ? (
            <p>
              Pressing the "Remove" button will change the state of the project to rejected,
              pressing the "Duplicate and remove" button will create a copy of the project and the
              current one will change de state to rejected.
            </p>
          ) : (
            <DuplicateProject project={project} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {!duplicate ? (
            <>
              {loadSpinnerDuplicate ? (
                <>
                  <SpinnerButtons text="Remove project..." textButton="Remove"></SpinnerButtons>
                  <Button className="botonComment">Duplicate and remove</Button>
                </>
              ) : (
                <>
                  <Button className="botonComment" onClick={clickRemove}>
                    Remove
                  </Button>
                  <Button className="botonComment" onClick={clickDuplicate}>
                    Duplicate and remove
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              {loadSpinnerDuplicate ? (
                <SpinnerButtons text="Duplicate project..." textButton="Duplicate"></SpinnerButtons>
              ) : (
                <button
                  className="botonComment btn btn-primary"
                  type="submit"
                  form="hook-form-duplicate"
                >
                  Duplicate
                </button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
