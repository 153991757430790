import { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { InitialState } from './config/TableConfigs';
import * as XLSX from 'xlsx/xlsx.mjs';

export const TableFullOptions = ({ columns, data, title, loading }) => {
  const columnsDisplay = useMemo(() => columns, [columns]);

  const downloadExcel = (table) => {
    const data = [];
    table.getPrePaginationRowModel()?.rows.forEach((row) => {
      data.push(row.original);
    });

    if (typeof data !== 'undefined') {
      const newData = data.map((row) => {
        delete row.uid;
        delete row.id;
        delete row.summary;
        delete row.subheadquartersId;
        delete row.departmentId;
        delete row.areaId;
        delete row.categoryId;
        delete row.startDate;
        delete row.expectedEndDate;
        delete row.investmentTypeId;
        delete row.currentApprover;
        delete row.selfApproval;
        delete row.visibility;
        delete row.createdBy;
        delete row.lastUpdatedBy;
        delete row.lastUpdatedDate;
        delete row.attachments;
        delete row.audits;
        delete row.comments;
        delete row.interesteds;
        delete row.notifications;
        delete row.subtype;
        delete row.headquartersId;
        delete row.createdBy;
        return row;
      });

      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'projects');
      //Buffer
      XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
      //Binary string
      XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
      //Download
      XLSX.writeFile(workBook, 'Projects.xlsx');
    }
  };

  return (
    <MaterialReactTable
      columns={columnsDisplay}
      data={data ?? []}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableColumnResizing={true}
      initialState={InitialState}
      state={{
        density: 'compact',
        showSkeletons: false,
        isLoading: loading,
        showProgressBars: false,
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [3, 5, 10, 15, 20, 25, 30, 50, 100],
      }}
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <p className='colorGrey form-label col-form-label col-xl-4"'>Total Budget:</p>
            <p className="dacsa-green fontSize-20 badge bg-primary">
              {table.getPrePaginationRowModel()?.rows.reduce((accum, row) => {
                if (typeof row !== 'undefined') {
                  return accum + row.original.budget;
                }
                return 0;
              }, 0)}
            </p>
          </div>
        );
      }}
      renderBottomToolbarCustomActions={({ table }) => {
        return (
          <button className="botonExcel btn btn-link" onClick={() => downloadExcel(table)}>
            Export to Excel
          </button>
        );
      }}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          color: theme.palette.text.secondary,
        }),
      }}
      muiTableBodyCellProps={{
        sx: (theme) => ({
          color: theme.palette.text.secondary,
        }),
      }}
    />
  );
};
