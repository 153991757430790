import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { API_PROJECT_URL } from '../../../config/ApiURLs';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { useProjectContext } from '../context/ProjectContext';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';

export function AddComment({ comments, project }) {
  const [loadSpinner, setLoadSpinner] = useState(false);
  const { showAddComment, setShowAddComment, setReloadComments, setReloadProject } =
    useProjectContext();

  const commentRef = useRef();
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [isWarning, setIsWarning] = useState(false);

  const addComments = () => {
    setLoadSpinner(true);
    const comment = commentRef.current.value;
    if (comment === '') {
      setLoadSpinner(false);
      return;
    }
    const newComment = {
      content: comment,
      commentType: 'GENERAL',
      projectUid: project.uid,
    };
    axiosApiInstance
      .post(API_PROJECT_URL + `/${project.uid}/comments`, newComment)
      .then(() => {
        setReloadProject(true);
        setReloadComments(true);
        setLoadSpinner(false);
        setIsError(false);
        setIsWarning(false);
        setModalResponse(true);
        setMsg('Comment has been correctly added!!');
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setLoadSpinner(false);
        if (error.response && error.response.data.TypeResponse === 1) {
          setIsError(false);
          setIsWarning(true);
          setMsg(errorMessage);
        } else {
          setIsError(true);
          setIsWarning(false);
          setMsg(errorMessage);
        }
        setModalResponse(true);
      });
    commentRef.current.value = null;
    setShowAddComment(false);
  };

  const scrollToBottom = () => {
    document.getElementById('footerDacsa').scrollIntoView(false);
  };

  const hideComponent = () => {
    setShowAddComment(false);
  };

  const handleModalCallbackButton = () => {
    setReloadProject(true);
    setModalResponse(false);
  };

  const handleModalCallbackClose = () => {
    setReloadProject(true);
    setModalResponse(false);
  };

  if (showAddComment) {
    return (
      <>
        <div id="scrollButton">
          <br />
          <Form.Control
            ref={commentRef}
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: '100px' }}
            className="indent"
          />
          <br />
          {!loadSpinner ? (
            <>
              {modalResponse && (
                <ModalResponse
                  showModal={modalResponse}
                  isError={isError}
                  isWarning={isWarning}
                  message={msg}
                  buttonAccept={!isError && !isWarning}
                  callbackButton={handleModalCallbackButton}
                  callbackClose={isError ? undefined : handleModalCallbackClose}
                />
              )}
              <Button className="botonComment indent" onClick={addComments}>
                Add comment
              </Button>
              &nbsp;
            </>
          ) : (
            <SpinnerButtons text="Adding comment..." textButton="Add comment"></SpinnerButtons>
          )}
          <Button className="botonComment" onClick={hideComponent}>
            Cancel
          </Button>
        </div>
        {scrollToBottom()}
      </>
    );
  }

  return (
    <>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      {!loadSpinner ? (
        <>
          <Button className="botonComment indent" onClick={() => setShowAddComment(true)}>
            Add comment
          </Button>
        </>
      ) : (
        <SpinnerButtons text="Adding comment..." textButton="Add comment"></SpinnerButtons>
      )}
    </>
  );
}
