import { Skeleton } from '@mui/material';
import { Form, Col, Row } from 'react-bootstrap';

export function SkeletonInvestmentTypeDetail({ project }) {
  const sizeLabel = 3;
  const sizeCol = 9;

  return (
    <>
      <br />
      <Col xl={{ order: 'first' }} className="col-md-8">
        <Row>
          <Col>
            <strong>
              <Skeleton width={180} />
            </strong>
          </Col>
        </Row>
        <Form.Group controlId="requestName">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            <Skeleton width={180} />
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="type">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            <Skeleton width={180} />
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="subtype">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            <Skeleton width={180} />
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="budget">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            <Skeleton width={180} />
          </Form.Label>
        </Form.Group>
        <Form.Group controlId="budget">
          <Form.Label className="colorGrey" column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Form.Label column xl={sizeCol}>
            <Skeleton width={180} />
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row} controlId="selfApproval">
          <Form.Label column xl={sizeLabel}>
            <Skeleton width={180} />
          </Form.Label>
          <Col className="checkIndent-1">
            <Skeleton width={180} />
          </Col>
        </Form.Group>
      </Col>
    </>
  );
}
