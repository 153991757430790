import { API_FUNCTIONALGROUP_VIEWERS_URL } from '../config/ApiURLs';
import { useCatalogFunctionalGroupsByType } from './useCatalogFunctionalGroupsByType';

export const useViewers = () => {
  const { catalogFunctionalGroupsByType: viewers } = useCatalogFunctionalGroupsByType(
    API_FUNCTIONALGROUP_VIEWERS_URL
  );

  return { viewers };
};
