import { useState, useRef, useEffect } from 'react';
import { Nav, Form, Col, Row, Badge, Container } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import CampoFecha from './CampoFecha';
import { NavLink, useNavigate } from 'react-router-dom';
import { useCatalogContext } from '../../../CatalogContext';
import { getCatalog } from '../../../services/CatalogService';
import { API_PROJECT_URL } from '../../../config/ApiURLs';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { AuthenticatedComponent } from '../../../components/authentication/AuthenticatedComponent';
import { appRoles } from '../../../config/authConfig';
import { useProjectContext } from '../context/ProjectContext';
import Documents from './Documents';
import { isArrayEmpty } from '../../../utils/CheckUtils';
import { SpinnerButtons } from '../../../components/SpinnerButtons';
import { ModalResponse } from '../../../components/ModalResponse';
import { SelectProjectManager } from './SelectProjectManager';
import { FormCatalogSelect } from '../../../components/FormCatalogSelect';

export function NewProject() {
  const { catalogs } = useCatalogContext();
  const navigate = useNavigate();
  const sizeLabel = 3;
  const sizeCol = 9;
  const tamanyoSelects = 6;

  const catalogType = getCatalog(catalogs, 'type');
  const catalogSubtype = getCatalog(catalogs, 'subtype');
  const catalogHeadquarters = getCatalog(catalogs, 'headquarters');
  const catalogArea = getCatalog(catalogs, 'area');
  const catalogCategory = getCatalog(catalogs, 'category');
  const catalogBadge = getCatalog(catalogs, 'badge');
  const catalogSubheadquarters = getCatalog(catalogs, 'subheadquarters');
  const catalogDepartment = getCatalog(catalogs, 'department');

  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('');
  const [subtypeList, setSubtypeList] = useState([]);
  const [haveSubheadquarters, setHaveSubheadquarters] = useState(false);
  const [subheadquartersList, setSubheadquartersList] = useState([]);
  const [step, setStep] = useState('one');
  const [showDepartment, setShowDepartment] = useState(false);

  const selectInputRefSubtype = useRef();
  const selectInputRefSubheadquarters = useRef();
  const { TaskSuperUser } = appRoles;
  const { setShowAddDocuments, setIsNewProject, documentsAttachedNewProject } = useProjectContext();
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [headquartersPl, setHeadquartersPl] = useState([]);
  const [isWarning, setIsWarning] = useState(false);
  const showTypeofproject = type === 'SPECIALS';

  useEffect(() => {
    setIsNewProject(true);
    if (type === 'PLANT') {
      let headquartersPlant = [];
      catalogHeadquarters.catalogValues.map((valueCatalog) => {
        if (valueCatalog.code !== 'ALL') {
          headquartersPlant.push(valueCatalog);
        }
        return headquartersPlant;
      });
      setHeadquartersPl(headquartersPlant);
    }
  }, [setIsNewProject, catalogHeadquarters, type]);

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
  } = useForm();

  const onClear = (ref) => {
    ref.current.clearValue();
  };

  const handleTypeChange = (obj) => {
    onClear(selectInputRefSubtype);
    if (obj === null) {
      setSubtypeList([]);
      setType('');
    } else {
      let subtypes = [];
      catalogSubtype.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === obj.code) {
          subtypes.push(valueCatalog);
        }
        return subtypes;
      });

      setSubtypeList(subtypes);
      setType(obj.code);
    }
  };

  const handleSubTypeChange = (obj) => {
    if (obj !== null) {
      setSubtype(obj.name);

      let catalogoSubheadquarters = [];
      catalogSubheadquarters.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === obj.code) {
          catalogoSubheadquarters.push(valueCatalog);
        }
        return catalogoSubheadquarters;
      });

      setSubheadquartersList(catalogoSubheadquarters);

      if (catalogoSubheadquarters.length > 0) {
        setSubheadquartersList(catalogoSubheadquarters);
        setHaveSubheadquarters(true);
      } else {
        setSubheadquartersList([]);
        setHaveSubheadquarters(false);
      }
    } else {
      setSubheadquartersList([]);
      setHaveSubheadquarters(false);
    }
  };

  const handleNext = () => {
    trigger(['type', 'subtype']);
    if (isValid) {
      setStep('two');
    }

    if (type === 'CORPORATE') {
      setShowDepartment(true);
    }
  };

  function addDocuments(formData, files) {
    files.forEach((file) => {
      formData.append('documents', file);
    });
  }

  const handleLoad = () => {
    setLoadSpinner(true);
  };

  const onSubmit = (data) => {
    setModalResponse(false);
    setLoadSpinner(true);
    let formData = new FormData(document.querySelector('#hook-form'));
    formData.set('projectManager', data.projectManager);
    formData.append('startDate', data.startDate.toDateString());
    formData.append('expectedEndDate', data.expectedEndDate.toDateString());
    formData.append('type', data.type);
    formData.append('subtype', data.subtype);

    if (formData.get('selfApproval') === 'on') {
      formData.set('selfApproval', true);
    }

    if (!isArrayEmpty(documentsAttachedNewProject))
      addDocuments(formData, documentsAttachedNewProject);

    axiosApiInstance
      .post(API_PROJECT_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const responseDetail = res.data;
        setProjectCode(responseDetail.data);
        setLoadSpinner(false);
        setMsg('Project created successfully!!');
        setIsError(false);
        setIsWarning(false);
        setModalResponse(true);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setLoadSpinner(false);
        if (error.response && error.response.data.TypeResponse === 1) {
          setIsError(false);
          setIsWarning(true);
          setMsg(errorMessage);
          setProjectCode(errorMessage.split('-')[0]);
        } else {
          setIsError(true);
          setIsWarning(false);
          setMsg(errorMessage);
        }
        setModalResponse(true);
      });
  };

  const handleModalCallbackButton = () => {
    navigate(`/project/${projectCode}`);
    setShowAddDocuments(true);
  };

  const handleModalCallbackClose = () => {
    navigate(`/dashboard`);
  };

  return (
    <Container>
      {modalResponse && (
        <ModalResponse
          showModal={modalResponse}
          isError={isError}
          isWarning={isWarning}
          message={msg}
          buttonAccept={!isError && !isWarning}
          callbackButton={handleModalCallbackButton}
          callbackClose={isError ? undefined : handleModalCallbackClose}
        />
      )}
      <br />
      <Col>
        <Row>
          <Col className="col-md-8">
            <h2 className="colorDarkGrey">Project Create</h2>
          </Col>
          {step === 'one' ? (
            <Col className="alignEnd">
              <button className="botonComment btn btn-primary" type="submit" form="hook-form-next">
                Next
              </button>
              &nbsp;
              <Nav.Link className="botonCancel btn btn-primary" as={NavLink} to={'/dashboard'}>
                Cancel
              </Nav.Link>
            </Col>
          ) : (
            <Col className="alignEnd">
              {loadSpinner ? (
                <SpinnerButtons text="Creating project..." textButton="Create"></SpinnerButtons>
              ) : (
                <button className="botonComment btn btn-primary" type="submit" form="hook-form">
                  Create
                </button>
              )}
              &nbsp;
              <Nav.Link className="botonCancel btn btn-primary" as={NavLink} to={'/dashboard'}>
                Cancel
              </Nav.Link>
            </Col>
          )}
        </Row>
      </Col>
      {step === 'one' ? (
        <Form id="hook-form-next" onSubmit={(() => handleLoad, handleSubmit(handleNext))}>
          <Row>
            <Form.Group as={Row} className="mb-3" controlId="type">
              <Form.Label column xl={sizeLabel}>
                Type:
              </Form.Label>
              <Col xl={sizeLabel}>
                <Controller
                  control={control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={catalogType.catalogValues}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={
                        value ? catalogType.catalogValues.find((x) => x.code === value) : value
                      }
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                        handleTypeChange(option);
                      }}
                      ref={ref}
                    />
                  )}
                />
                {errors.type && (
                  <Form.Text className="text-danger">The type field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="subtype">
              <Form.Label column xl={sizeLabel}>
                Subtype:
              </Form.Label>
              <Col xl={sizeLabel}>
                <Controller
                  control={control}
                  name="subtype"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      placeholder="Select value"
                      isClearable
                      options={subtypeList}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.code}
                      value={value ? subtypeList.find((x) => x.code === value) : null}
                      onChange={(option) => {
                        onChange(option ? option.code : option);
                        handleSubTypeChange(option);
                      }}
                      ref={selectInputRefSubtype}
                    />
                  )}
                />
                {errors.subtype && (
                  <Form.Text className="text-danger">The subtype field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
          </Row>
          <hr className="createProject" />
        </Form>
      ) : (
        <Form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
          <Badge className="dacsa-yellow">{type}</Badge> &nbsp;
          <Badge className="dacsa-yellow">{subtype}</Badge>
          <Row>
            <Col xl={{ order: 'first' }} className="col-md-8">
              <Row>
                <Col>
                  <strong>Details</strong>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3" controlId="name">
                    <Form.Label column xl={sizeLabel}>
                      Project Name:
                    </Form.Label>
                    <Col xl={sizeCol}>
                      <Form.Control
                        type="text"
                        placeholder="Enter project name"
                        {...register('name', { required: true })}
                      />
                      {errors.name && (
                        <Form.Text className="text-danger">
                          The project name field is required
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Row>
                    <Form.Label column xl={sizeLabel}>
                      Summary:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Project summary"
                      className="indent-1 textareaCreate"
                      cols="40"
                      rows="5"
                      {...register('summary', { required: true })}
                    />
                    {errors.summary && (
                      <Form.Text className="text-danger">The summary field is required</Form.Text>
                    )}
                  </Row>
                  <br />
                  <Form.Group as={Row} className="mb-3" controlId="projectManager">
                    <Form.Label column xl={sizeLabel}>
                      Project Manager:
                    </Form.Label>
                    <Col xl={tamanyoSelects}>
                      <Controller
                        control={control}
                        name="projectManager"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <SelectProjectManager value={value} onChange={onChange} name={name} />
                        )}
                      />
                      {errors.projectManager && (
                        <Form.Text className="text-danger">
                          The project manager field is required
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="headquarters">
                    <Form.Label column xl={sizeLabel}>
                      Headquarters:
                    </Form.Label>
                    <Col xl={tamanyoSelects}>
                      <Controller
                        control={control}
                        name="headquarters"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            placeholder="Select value"
                            isClearable
                            options={
                              headquartersPl.length > 0 && type === 'PLANT'
                                ? headquartersPl
                                : catalogHeadquarters.catalogValues
                            }
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            value={
                              value
                                ? headquartersPl.length > 0
                                  ? headquartersPl.find((x) => x.code === value)
                                  : catalogHeadquarters.catalogValues.find((x) => x.code === value)
                                : value
                            }
                            onChange={(option) => {
                              onChange(option ? option.code : option);
                            }}
                            ref={ref}
                          />
                        )}
                      />
                      {errors.headquarters && (
                        <Form.Text className="text-danger">
                          The headquarters field is required
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  {haveSubheadquarters && (
                    <Form.Group as={Row} className="mb-3" controlId="subheadquarters">
                      <Form.Label column xl={sizeLabel}>
                        Sub-headquarters:
                      </Form.Label>
                      <Col xl={tamanyoSelects}>
                        <Controller
                          control={control}
                          name="subheadquarters"
                          rules={{ required: true }}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              name={name}
                              placeholder="Select value"
                              isClearable
                              options={subheadquartersList}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.code}
                              value={
                                value ? subheadquartersList.find((x) => x.code === value) : value
                              }
                              onChange={(option) => onChange(option ? option.code : option)}
                              ref={selectInputRefSubheadquarters}
                            />
                          )}
                        />
                        {errors.subheadquarters && (
                          <Form.Text className="text-danger">
                            The sub-headquarters field is required
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  )}
                  {showDepartment && (
                    <Form.Group as={Row} className="mb-3" controlId="department">
                      <Form.Label column xl={sizeLabel}>
                        Department:
                      </Form.Label>
                      <Col xl={tamanyoSelects}>
                        <Controller
                          control={control}
                          name="department"
                          rules={{ required: true }}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              name={name}
                              placeholder="Select value"
                              isClearable
                              options={catalogDepartment.catalogValues}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.code}
                              value={
                                value
                                  ? catalogDepartment.catalogValues.find((x) => x.code === value)
                                  : value
                              }
                              onChange={(option) => onChange(option ? option.code : option)}
                              ref={ref}
                            />
                          )}
                        />
                        {errors.department && (
                          <Form.Text className="text-danger">
                            The department field is required
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  )}
                  {showTypeofproject && (
                    <FormCatalogSelect
                      as={Row}
                      catalogType={'typeofproject'}
                      control={control}
                      evalVisibleCondition={true}
                      errors={errors}
                      rules={{ required: true }}
                      sizeLabel={sizeLabel}
                      className="mb-3"
                      sizeCol={tamanyoSelects}
                    />
                  )}
                  <Form.Group as={Row} className="mb-3" controlId="area">
                    <Form.Label column xl={sizeLabel}>
                      Area:
                    </Form.Label>
                    <Col xl={tamanyoSelects}>
                      <Controller
                        control={control}
                        name="area"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            placeholder="Select value"
                            isClearable
                            options={catalogArea.catalogValues}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            value={
                              value
                                ? catalogArea.catalogValues.find((x) => x.code === value)
                                : value
                            }
                            onChange={(option) => onChange(option ? option.code : option)}
                            ref={ref}
                          />
                        )}
                      />
                      {errors.area && (
                        <Form.Text className="text-danger">The area field is required</Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="category">
                    <Form.Label column xl={sizeLabel}>
                      Category:
                    </Form.Label>
                    <Col xl={tamanyoSelects}>
                      <Controller
                        control={control}
                        name="category"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            placeholder="Select value"
                            isClearable
                            options={catalogCategory.catalogValues}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            value={
                              value
                                ? catalogCategory.catalogValues.find((x) => x.code === value)
                                : value
                            }
                            onChange={(option) => onChange(option ? option.code : option)}
                            ref={ref}
                          />
                        )}
                      />
                      {errors.category && (
                        <Form.Text className="text-danger">
                          The category field is required
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="budget">
                    <Form.Label column xl={sizeLabel}>
                      Budget:
                    </Form.Label>
                    <Col xl="3">
                      <Form.Control
                        placeholder="Enter budget"
                        {...register('budget', {
                          pattern: {
                            value: /^\d*$/,
                            message: 'Only are allowed whole numbers without separators',
                          },
                          required: {
                            value: true,
                            message: 'The budget field is required',
                          },
                        })}
                      />
                      {errors.budget && (
                        <Form.Text className="text-danger">{errors.budget.message}</Form.Text>
                      )}
                    </Col>
                    <Col xl="3">
                      <Controller
                        control={control}
                        name="badge"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            placeholder="Select"
                            isClearable
                            options={catalogBadge.catalogValues}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.code}
                            value={
                              value
                                ? catalogBadge.catalogValues.find((x) => x.code === value)
                                : value
                            }
                            onChange={(option) => onChange(option ? option.code : option)}
                            ref={ref}
                          />
                        )}
                      />
                      {errors.badge && (
                        <Form.Text className="text-danger">The badge field is required</Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="startDate">
                    <Form.Label column xl={sizeLabel}>
                      Start date:
                    </Form.Label>
                    <Col sm={tamanyoSelects}>
                      <CampoFecha name="startDate" control={control} />
                      {errors.startDate && (
                        <Form.Text className="text-danger">
                          The start date field is required
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="expectedEndDate">
                    <Form.Label column xl={sizeLabel}>
                      Expected end date:
                    </Form.Label>
                    <Col sm={tamanyoSelects}>
                      <CampoFecha name="expectedEndDate" control={control} />
                      {errors.expectedEndDate && (
                        <Form.Text className="text-danger">
                          The expected end date field is required
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <AuthenticatedComponent roles={[TaskSuperUser]}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="selfApproval">
                      <Form.Label column xl={sizeLabel}>
                        Self approved:
                      </Form.Label>
                      <Col xl="2" className="checkIndent-1">
                        <Form.Check {...register('selfApproval')} type="checkbox" />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </AuthenticatedComponent>
              <Row>
                <Col>
                  <strong>Documents</strong>
                </Col>
                <Documents />
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
}
