import { useState, useEffect } from 'react';
import { API_INVESTMENT_TYPE_URL } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { isArrayEmpty } from '../utils/CheckUtils';
import { useInvestmentContext } from '../pages/Investment/components/context/InvestmentContext';

export const useFunctionalGroups = (uidInvestmentType, type) => {
  const [functionalGroups, setFunctionalGroups] = useState([]);
  const [error, setError] = useState('');
  const { reloadFunctionalGroups, setReloadFunctionalGroups } = useInvestmentContext();

  const isFunctionalGroupsLoading = isArrayEmpty(functionalGroups);

  useEffect(() => {
    const fetchFunctionalGroupsData = async () => {
      try {
        const { data } = await axiosApiInstance.get(
          API_INVESTMENT_TYPE_URL + `/${uidInvestmentType}/functional-groups?group-type=${type}`
        );
        const responseDetail = data;
        setFunctionalGroups(responseDetail.data);
        setReloadFunctionalGroups(false);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setReloadFunctionalGroups(false);
        setError('Error Fetching Investment Type ->' + errorMessage);
      }
    };

    if (isFunctionalGroupsLoading || reloadFunctionalGroups) {
      fetchFunctionalGroupsData();
    }
  }, [
    setFunctionalGroups,
    uidInvestmentType,
    isFunctionalGroupsLoading,
    type,
    reloadFunctionalGroups,
    setReloadFunctionalGroups,
  ]);

  return { functionalGroups, error, isFunctionalGroupsLoading };
};
