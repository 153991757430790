import React from 'react';
import { useRouteError } from 'react-router-dom';

import { LogoApp } from '../components/LogoApp';

export function ErrorPage({ message }) {
  const error = useRouteError();

  if (error) console.error(error);

  return (
    <LogoApp>
      <h5>
        <i>Sorry, an unexpected error has occurred.</i>
      </h5>
      <p>
        <i>{message || (error && (error.statusText || error.message))}</i>
      </p>
    </LogoApp>
  );
}
