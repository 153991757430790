import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import authService from '../services/authorization/AuthorizationService';

export const useSearchGrapUsers = (searchTerm) => {
  const [searchResults, setSearchResults] = useState([]);
  const { instance } = useMsal();

  useEffect(() => {
    async function searchUsers() {
      if (searchTerm.length >= 3) {
        const grahClient = await authService.getGraphClient();
        grahClient
          .api(
            `/users?$search="displayName:${searchTerm}" OR "mail:${searchTerm}" OR  "userPrincipalName:${searchTerm}"`
          )
          .header('ConsistencyLevel', 'eventual')
          .get()
          .then((response) => {
            setSearchResults(response.value);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSearchResults([]);
      }
    }
    searchUsers();
  }, [searchTerm, instance]);

  return searchResults;
};
