import { useState, useEffect, useCallback } from 'react';
import { useCatalogContext } from '../CatalogContext';
import { API_PROJECT_URL } from '../config/ApiURLs';
import axiosApiInstance from '../config/axiosApiInstance';
import { useProjectContext } from '../pages/Project/context/ProjectContext';
import { getCatalogValue } from '../services/CatalogService';
import { isObjectEmpty } from '../utils/CheckUtils';

export const useProjectDetail = (codeProject) => {
  const [project, setProject] = useState({});
  const { catalogs } = useCatalogContext();
  const { reloadProject, setReloadProject } = useProjectContext();

  const isProjectLoading = isObjectEmpty(project);

  const setProjectCatalogDescription = useCallback(
    (project) => {
      const subtypeDescription = getCatalogValue(catalogs, 'subtype', project.subtype);
      const headquartersDescription = getCatalogValue(
        catalogs,
        'headquarters',
        project.headquartersId
      );
      const areaDescription = getCatalogValue(catalogs, 'area', project.areaId);
      const departmentDescription = getCatalogValue(catalogs, 'department', project.departmentId);
      const badgeDescription = getCatalogValue(catalogs, 'badgeDescription', project.badgeId);
      const categoryDescription = getCatalogValue(catalogs, 'category', project.categoryId);
      const typeofprojectDescription = getCatalogValue(
        catalogs,
        'typeofproject',
        project.typeOfProjectId
      );
      return {
        ...project,
        subtypeDescription,
        headquartersDescription,
        areaDescription,
        departmentDescription,
        badgeDescription,
        categoryDescription,
        typeofprojectDescription,
      };
    },
    [catalogs]
  );

  useEffect(() => {
    const fetchProjectDetailData = async () => {
      try {
        const { data } = await axiosApiInstance.get(API_PROJECT_URL + `/code/${codeProject}`);
        const responseDetail = data;
        const project = setProjectCatalogDescription(responseDetail.data);
        setProject(project);
        setReloadProject(false);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        setReloadProject(false);
        console.log('Error Feching Project detail -> ' + errorMessage);
      }
    };

    if (isProjectLoading || reloadProject) {
      fetchProjectDetailData();
    }
  }, [
    isProjectLoading,
    codeProject,
    setProjectCatalogDescription,
    reloadProject,
    setReloadProject,
  ]);

  return { project, isProjectLoading };
};
