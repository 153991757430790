import { Form, Col } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { useCatalogContext } from '../CatalogContext';
import { getCatalog } from '../services/CatalogService';

export function FormCatalogSelect({
  as,
  catalogType,
  control,
  errors,
  rules,
  sizeLabel,
  sizeCol,
  className,
}) {
  const { catalogs } = useCatalogContext();
  const catalog = getCatalog(catalogs, catalogType);

  return (
    <Form.Group as={as} className={className} controlId={catalogType}>
      <Form.Label column xl={sizeLabel}>
        {catalog.name}:
      </Form.Label>
      <Col xl={sizeCol}>
        <Controller
          control={control}
          name={catalogType}
          rules={rules}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              name={name}
              placeholder="Select value"
              isClearable
              options={catalog.catalogValues}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.code}
              value={value ? catalog.catalogValues.find((x) => x.code === value) : value}
              onChange={(option) => onChange(option ? option.code : option)}
              ref={ref}
            />
          )}
        />
        {errors[catalogType] && (
          <Form.Text className="text-danger">The {catalog.name} field is required</Form.Text>
        )}
      </Col>
    </Form.Group>
  );
}
