import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSearchGrapUsers } from '../../../hooks/useSearchGraphUsers';

export function SelectProjectManager({ name, onChange, value }) {
  const [searchTerm, setSearchTerm] = useState('');
  const searchResults = useSearchGrapUsers(searchTerm);
  useEffect(() => {
    if (value) setSearchTerm(value);
  }, [value]);

  return (
    <Select
      name={name}
      placeholder="Search Project Manager"
      isClearable
      defaultValue={null}
      options={searchResults}
      getOptionLabel={(option) => option.displayName}
      getOptionValue={(option) => option.displayName}
      onInputChange={(value) => {
        setSearchTerm(value);
      }}
      value={value ? searchResults.find((x) => x.mail === value) : value}
      onChange={(option) => {
        onChange(option ? option.mail : option);
      }}
    />
  );
}
