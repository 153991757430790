import { Tab, Tabs } from 'react-bootstrap';
import { SkeletonApproversViewers } from './SkeletonApproversViewers';

export const SkeletonTabMenuInvestment = () => {
  return (
    <>
      <Tabs
        defaultActiveKey="approvers"
        transition={false}
        id="noanim-tab-example"
        className="mb-3 navColorGrey alignEnd"
      >
        <Tab eventKey="approvers" title="Approvers">
          <SkeletonApproversViewers />
        </Tab>
        <Tab eventKey="viewers" title="Viewers">
          <SkeletonApproversViewers />
        </Tab>
      </Tabs>
    </>
  );
};
