import { Tab, Tabs } from 'react-bootstrap';
import { Comments } from './Comments';
import Documents from './Documents';
import { useProjectContext } from '../context/ProjectContext';

export const TabMenu = ({ project }) => {
  const { key, setKey } = useProjectContext();
  return (
    <>
      <div className="navColorGrey">
        <Tabs
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="comments" title="Comments">
            <Comments project={project} />
          </Tab>
          <Tab eventKey="documents" title="Documents">
            <Documents />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
