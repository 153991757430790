import { Skeleton } from '@mui/material';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

export const SkeletonTabMenu = () => {
  return (
    <>
      <div className="navColorGrey">
        <Tabs>
          <Tab eventKey="comments" title={'Comments'}>
            <Skeleton width={180} height={300} />
          </Tab>
          <Tab eventKey="documents" title="Documents">
            <Skeleton width={180} height={300} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
