import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import { SignInButton } from '../components/authentication/SignInButton';
import MSLogo from '../assets/MSLogo.png';
import { LogoApp } from '../components/LogoApp';
import { useIsUserAuthorized } from '../hooks/useIsUserAuhtorized';

export function Home() {
  const isAuthenticated = useIsAuthenticated();
  const isUserValid = useIsUserAuthorized();

  if (isAuthenticated) {
    if (isUserValid) return <Navigate to="/dashboard" replace />;
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <LogoApp>
      <p>Sign in with</p>
      <p>
        <img
          alt="Azure AD"
          title="Sign in with Azure AD"
          className="gl-button-icon js-lazy-loaded"
          src={MSLogo}
          loading="lazy"
          data-qa_selector="js_lazy_loaded_content"
        />
        <SignInButton />
      </p>
    </LogoApp>
  );
}
