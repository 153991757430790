import { Form, Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useCatalogContext } from '../../../CatalogContext';
import { getCatalog } from '../../../services/CatalogService';
import { useEffect, useRef, useState } from 'react';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_INVESTMENT_TYPE_URL } from '../../../config/ApiURLs';
import { useInvestmentContext } from './context/InvestmentContext';

export function EditInvestmentType({ investmentType }) {
  const sizeLabel = 3;
  const sizeCol = 6;

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: investmentType.code,
      name: investmentType.name,
      type: investmentType.type,
      subtype: investmentType.subtype,
      minAmount: investmentType.minAmount,
      maxAmount: investmentType.maxAmount,
      requiresAttached: investmentType.requiresAttached,
    },
  });

  const { catalogs } = useCatalogContext();
  const catalogType = getCatalog(catalogs, 'type');
  const catalogSubtype = getCatalog(catalogs, 'subtype');
  const [setType] = useState('');
  const [subtypeList, setSubtypeList] = useState([]);
  const selectInputRefSubtype = useRef();
  const {
    setReloadInvestment,
    setEditInvestment,
    setLoadSpinnerInvestmentType,
    setModalResponseEditInvestment,
    setIsErrorEditInvestment,
    setMsgEditInvestment,
  } = useInvestmentContext();

  useEffect(() => {
    if (
      typeof investmentType.subtypeDescription !== 'undefined' &&
      investmentType.subtypeDescription !== null
    ) {
      let subtypes = [];
      catalogSubtype.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === investmentType.type) {
          subtypes.push(valueCatalog);
        }
        return subtypes;
      });

      setSubtypeList(subtypes);
    }
  }, [setSubtypeList, catalogSubtype, investmentType]);

  const onClear = (ref) => {
    ref.current.clearValue();
  };

  const handleTypeChange = (obj) => {
    onClear(selectInputRefSubtype);
    if (obj === null) {
      setSubtypeList([]);
      setType('');
    } else {
      let subtypes = [];
      catalogSubtype.catalogValues.map((valueCatalog) => {
        if (valueCatalog.parentValueCatalogId === obj.code) {
          subtypes.push(valueCatalog);
        }
        return subtypes;
      });

      setSubtypeList(subtypes);
      setType(obj.code);
    }
  };

  const onSubmit = async (data) => {
    setLoadSpinnerInvestmentType(true);
    setModalResponseEditInvestment(false);
    await axiosApiInstance
      .put(API_INVESTMENT_TYPE_URL, data)
      .then(() => {
        setReloadInvestment(true);
        setEditInvestment(false);
        setIsErrorEditInvestment(false);
        setMsgEditInvestment('Modified investment type has been saved correctly!!');
        setLoadSpinnerInvestmentType(false);
        setModalResponseEditInvestment(true);
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data.Message : error.message;
        this.setState({ errorMessage: errorMessage });
        setIsErrorEditInvestment(true);
        setMsgEditInvestment(errorMessage);
        setLoadSpinnerInvestmentType(false);
        setModalResponseEditInvestment(true);
      });
  };

  return (
    <>
      <br />
      <Form id="hook-form-edit-investment" onSubmit={handleSubmit(onSubmit)}>
        <Col xl={{ order: 'first' }} className="col-md-8">
          <Row>
            <Col>
              <strong>Details</strong>
            </Col>
          </Row>
          <Row>
            <Form.Group as={Row} className="mb-1" controlId="name">
              <Form.Label column xl={sizeLabel}>
                Name:
              </Form.Label>
              <Col xl={9}>
                <Form.Control
                  type="text"
                  className="investementTypeName indent-1"
                  placeholder="Enter investment type name"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <Form.Text className="text-danger">The name field is required</Form.Text>
                )}
              </Col>
            </Form.Group>
          </Row>
          <Form.Group as={Row} className="mb-1" controlId="type">
            <Form.Label column xl={sizeLabel}>
              Type:
            </Form.Label>
            <Col xl={sizeCol}>
              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    name={name}
                    placeholder="Select value"
                    isClearable
                    options={catalogType.catalogValues}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    value={value ? catalogType.catalogValues.find((x) => x.code === value) : value}
                    onChange={(option) => {
                      onChange(option ? option.code : option);
                      handleTypeChange(option);
                    }}
                    ref={ref}
                  />
                )}
              />
              {errors.type && (
                <Form.Text className="text-danger">The type field is required</Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="subtype">
            <Form.Label column xl={sizeLabel}>
              Subtype:
            </Form.Label>
            <Col xl={sizeCol}>
              <Controller
                control={control}
                name="subtype"
                rules={{ required: true }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    name={name}
                    placeholder="Select value"
                    isClearable
                    options={subtypeList}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.code}
                    value={value ? subtypeList.find((x) => x.code === value) : value}
                    onChange={(option) => {
                      onChange(option ? option.code : option);
                    }}
                    ref={selectInputRefSubtype}
                  />
                )}
              />
              {errors.subtype && (
                <Form.Text className="text-danger">The subtype field is required</Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="minAmount">
            <Form.Label column xl={sizeLabel}>
              Min Amount:
            </Form.Label>
            <Col xl={sizeCol}>
              <Form.Control
                type="number"
                placeholder="Enter min amount"
                {...register('minAmount', { required: true })}
              />
              {errors.minAmount && (
                <Form.Text className="text-danger">The min amount field is required</Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-1" controlId="maxAmount">
            <Form.Label column xl={sizeLabel}>
              Max Amount:
            </Form.Label>
            <Col xl={sizeCol}>
              <Form.Control
                type="number"
                placeholder="Enter max amount"
                {...register('maxAmount', { required: true })}
              />
              {errors.maxAmount && (
                <Form.Text className="text-danger">The max amount field is required</Form.Text>
              )}
            </Col>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-1" controlId="requiresAttached">
                <Form.Label column xl={sizeLabel}>
                  Requires attachment:
                </Form.Label>
                <Col xl="2" className="checkIndent-2">
                  <Form.Check {...register('requiresAttached')} type="checkbox" />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Form>
    </>
  );
}
