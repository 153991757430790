import PropTypes from 'prop-types';
import { FormDetailField } from './FormDetailField';
import { useCatalogContext } from '../CatalogContext';
import { getCatalog } from '../services/CatalogService';

FormCatalogDetailField.propTypes = {
  controlId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  sizeLabel: PropTypes.number,
  sizeCol: PropTypes.number,
  catalogType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

/**
 * Creates a FormCatalogDetailField component.
 *
 * @param {string} controlId - The ID of the form control
 * @param {string} className - The class name for styling
 * @param {number} sizeLabel - The size of the label column
 * @param {number} sizeCol - The size of the value column
 * @param {string} label - The label for the field
 * @param {string} value - The value to be displayed
 * @return {JSX.Element} A FormCatalogDetailField component
 */
export function FormCatalogDetailField({
  controlId,
  className,
  sizeLabel,
  sizeCol,
  catalogType,
  value,
}) {
  const { catalogs } = useCatalogContext();
  const catalog = getCatalog(catalogs, catalogType);

  return (
    <FormDetailField
      className={className}
      controlId={controlId}
      sizeLabel={sizeLabel}
      sizeCol={sizeCol}
      label={catalog.name}
      value={value}
    />
  );
}
