import React from 'react';

import { Button } from 'react-bootstrap';
import { loginRequest } from '../../config/authConfig';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const SignInButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  const handleLogin = () => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((e) => {
        instance.loginPopup(loginRequest).catch((e) => {
          console.log(e);
        });
      });
    }
  };

  return (
    <Button variant="link" onClick={handleLogin}>
      Azure AD
    </Button>
  );
};
