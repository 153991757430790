import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export function useSuperUserProjectColumns() {
  const SuperUserProjectColumns = [
    {
      accessorKey: 'code', //access nested data with dot notation
      header: 'Request Code',
      Cell: ({ cell, renderedCellValue }) => (
        <Nav.Link className="linkTable" as={NavLink} to={'/project/' + cell.getValue()}>
          {renderedCellValue}
        </Nav.Link>
      ),
    },
    {
      accessorKey: 'name', //access nested data with dot notation
      header: 'Name',
    },
    {
      accessorKey: 'capexCode', //access nested data with dot notation
      header: 'CAPEX Code',
    },
    {
      accessorKey: 'type', //access nested data with dot notation
      header: 'Project Type',
    },
    {
      //accessorFn: (row) => getCatalogValue('subtype', row.subtype),
      accessorKey: 'subtypeDescription',
      header: 'Project SubType',
    },
    {
      accessorFn: (row) => new Date(row.createdDate).toLocaleDateString('en-GB'),
      header: 'Created Date',
      sortingFn: (rowA, rowB) =>
        Date.parse(rowA.original.createdDate) < Date.parse(rowB.original.createdDate) ? -1 : 1,
    },
    {
      accessorKey: 'projectManager',
      header: 'Project Manager',
    },
    {
      accessorKey: 'status', //access nested data with dot notation
      header: 'Status',
    },
    {
      //accessorFn: (row) => getCatalogValue('headquarters', row.headquartersId), //row.headquartersId,
      accessorKey: 'headquartersDescription',
      header: 'Headquarters',
    },
    {
      accessorKey: 'budget',
      header: 'Budget',
    },
    {
      accessorKey: 'badgeId',
      header: 'Badge',
    },
  ];

  return SuperUserProjectColumns;
}
