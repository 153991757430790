import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

import '../../../styles/CampoFecha.css';

const CampoFecha = ({ name, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value } }) => (
        <DatePicker
          className="datePicker-custom-input"
          customInput={<input />}
          dayClassName={() => 'datePicker-datepicker-day-class'}
          popperClassName="datePicker-datepicker-class"
          onChange={onChange}
          onBlur={onBlur}
          selected={value ? new Date(value) : ''}
          minDate={new Date()}
          dateFormat="dd/MM/yyyy"
          todayButton="TODAY"
        />
      )}
    />
  );
};

export default CampoFecha;
