import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { utcStringDateTimeToLocalDateTimeFormat } from '../../../utils/DateTimeUtils';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import './DocumentItem.css';
import axiosApiInstance from '../../../config/axiosApiInstance';
import { API_ATTACHMENTS } from '../../../config/ApiURLs';
import { useProjectContext } from '../context/ProjectContext';

export function DocumentItem({ documento }) {
  const { setReloadDocuments } = useProjectContext();
  const { setModalResponseDocument, setIsErrorDocument, setMsgDocument, setIsWarningDocument } =
    useProjectContext();

  const downloadData = (data, filename) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    if (typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(data, filename);
    } else {
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleDownload = async (documento) => {
    try {
      const { data } = await axiosApiInstance.get(API_ATTACHMENTS + `/${documento.uid}`, {
        responseType: 'blob',
      });
      downloadData(data, documento.fileName);
      setMsgDocument('The document has been downloaded successfully!!');
      setIsErrorDocument(false);
      setIsWarningDocument(false);
      setModalResponseDocument(true);
    } catch (error) {
      setIsErrorDocument(true);
      setIsWarningDocument(false);
      setMsgDocument(error.message);
      setModalResponseDocument(true);
    }
  };

  const handleDelete = async (documento) => {
    try {
      await axiosApiInstance.delete(API_ATTACHMENTS + `/${documento.uid}`);
      setReloadDocuments(true);
      setMsgDocument('The project document has been deleted correctly!!');
      setIsErrorDocument(false);
      setIsWarningDocument(false);
      setModalResponseDocument(true);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.Message : error.message;
      setIsErrorDocument(true);
      setIsWarningDocument(false);
      setMsgDocument(errorMessage);
      setModalResponseDocument(true);
    }
  };

  return (
    <>
      <Row id="align-row">
        <Col>
          <Row id="align-row" className="right">
            <Col className="col-1">
              <div className="icon">
                <FileIcon
                  extension={documento.fileExtension}
                  {...defaultStyles[documento.fileExtension]}
                />
              </div>
            </Col>
            <Col>
              <button className="botonExcel btn btn-link" onClick={() => handleDownload(documento)}>
                {documento.fileName}
              </button>
            </Col>
          </Row>
        </Col>
        <Col className="col-1">{documento.fileSize}</Col>
        <Col className="col-3">{documento.createdBy}</Col>
        <Col className="col-2">{utcStringDateTimeToLocalDateTimeFormat(documento.createdDate)}</Col>
        <Col className="col-1 width-delete" id="align-col-end">
          <IconButton onClick={() => handleDelete(documento)}>
            <Delete className="link" />
          </IconButton>
        </Col>
      </Row>
    </>
  );
}
